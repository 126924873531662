import {
  Firestore,
  QueryCompositeFilterConstraint,
  QueryFieldFilterConstraint,
  and,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import { addSentenceStructureToStringArray, stringExists } from '.'
import {
  ActionToolAccountWithId,
  ActionToolUserWithId,
  FirestoreCollections,
  FriendSearchFilters,
  UserAction
} from '../types/firebase-types'
import { LocationResponse, LocationType } from '../types/location-types'
import { isInBrowser } from './browser'
import { getSanitySlug } from './sanity'
import { createSearchableString } from './string'

export const createNewUser = (): FirestoreCollections.ActionToolAccountUser => {
  return {
    action_history: [],
    saved_actions: [],
    profile: {
      public: { name: null },
      private: {
        pronouns: '',
        twitter: '',
        linkedin: '',
        website: '',
        bio: ''
      }
    },
    settings: {
      is_private: true,
      subscribed_to_ini: false, // TODO: hook into BeeHiv api for this
      privacy: {
        hide_history: false,
        hide_location: false,
        hide_pronouns: false,
        hide_skills_cobenefits: false,
        hide_socials: false
      }
    }
  }
}

export const makeUserPremium = async (db: Firestore, user_id?: string, beehiiv_id?: string) => {
  if (!user_id || !beehiiv_id) {
    return
  }
  return await updateDoc(doc(db, 'accounts', user_id), 'premium', true, 'beehiiv_id', beehiiv_id)
}

export const addFriend = async (db: Firestore, user_id?: string, friend_id?: string) => {
  if (!user_id || !friend_id) {
    return
  }
  return await updateDoc(doc(db, 'accounts', user_id), 'user.friends', arrayUnion(friend_id))
}

export const removeFriend = async (db: Firestore, user_id?: string, friend_id?: string) => {
  if (!user_id || !friend_id) {
    return
  }
  const updates = await updateDoc(
    doc(db, 'accounts', user_id),
    'user.friends',
    arrayRemove(friend_id)
  )
}

export const fetchFriends = async (db: Firestore, filters: FriendSearchFilters) => {
  const nameQuery = (name: string) => {
    const searchableName = name?.split(' ').map(fragment => createSearchableString(fragment))
    return where('user.profile.public.name_searchable', 'array-contains-any', searchableName)
  }

  const cobenefitsQuery = (cobenefits: typeof filters.cobenefits) => {
    return and(
      where('user.settings.privacy.hide_skills_cobenefits', '==', false),
      where('user.profile.private.cobenefits', 'array-contains-any', cobenefits)
    )
  }

  const locationQuery = (state: string) => {
    return and(
      where('user.settings.privacy.hide_location', '==', false),
      where('user.profile.private.location_data.state', '==', state)
    )
  }

  const skillsQuery = (skills: typeof filters.skills) => {
    return and(
      where('user.settings.privacy.hide_skills_cobenefits', '==', false),
      where('user.profile.private.skills', 'array-contains-any', skills)
    )
  }

  const queryUsers = async (
    contraint: QueryFieldFilterConstraint | QueryCompositeFilterConstraint,
    existingIds?: string[]
  ) => {
    const baseQuery = [where('user.settings.is_private', '==', false)]

    if (existingIds && existingIds.length > 0) {
      baseQuery.push(where(documentId(), 'in', existingIds))
    }
    const queryAll = query(collection(db, 'accounts'), and(and(...baseQuery), contraint))
    const querySnapshot = await getDocs(queryAll)

    const docs: ActionToolAccountWithId[] = []

    querySnapshot.forEach(userDoc =>
      docs.push({
        ...userDoc.data(),
        id: userDoc.id
      } as ActionToolAccountWithId)
    )

    return docs
  }

  let allDocs: ActionToolAccountWithId[] = []
  if (filters.name && stringExists(filters.name)) {
    allDocs = await queryUsers(nameQuery(filters.name))
  }

  if (filters.cobenefits && filters.cobenefits.length > 0) {
    const existingIds = allDocs.map(({ id }) => id)
    allDocs = await queryUsers(cobenefitsQuery(filters.cobenefits), existingIds)
  }

  if (filters.location) {
    const locationData = await fetchLocationData(filters.location)
    if (locationData.state?.long_name) {
      const existingIds = allDocs.map(({ id }) => id)

      allDocs = await queryUsers(locationQuery(locationData.state?.long_name), existingIds)
    }
  }

  if (filters.skills && filters.skills.length > 0) {
    const existingIds = allDocs.map(({ id }) => id)

    allDocs = await queryUsers(skillsQuery(filters.skills), existingIds)
  }

  const usersWithPrivateDataStripped = allDocs
    .map(
      user =>
        user?.user &&
        stripPrivateData({
          id: user.id,
          is_verified: user.stripe_verification?.status == 'verified',
          ...user.user
        })
    )
    .filter(exists => exists)

  return usersWithPrivateDataStripped
}

export const stripPrivateData = (doc: ActionToolUserWithId) => {
  if (doc.settings?.is_private) return
  if (doc.settings?.privacy?.hide_location == true) {
    delete doc.profile.private.location
    delete doc.profile.private.location_data
  }
  if (doc.settings?.privacy?.hide_pronouns == true) {
    delete doc.profile.private.pronouns
  }
  if (doc.settings?.privacy?.hide_skills_cobenefits == true) {
    delete doc.profile.private.skills
    delete doc.profile.private.cobenefits
  }
  if (doc.settings?.privacy?.hide_socials == true) {
    delete doc.profile.private.website
    delete doc.profile.private.linkedin
    delete doc.profile.private.threads
    delete doc.profile.private.twitter
    delete doc.profile.private.bluesky
  }
  if (doc.settings?.privacy?.hide_history == true) {
    delete doc.action_history
  }
  return doc
}

export type FriendActionsQueryResponse = {
  profiles: ActionToolAccountWithId[]
  actions: UserAction[]
}
export const getFriendActions = async (
  db: Firestore,
  ids: string[]
): Promise<FriendActionsQueryResponse> => {
  const profiles: ActionToolAccountWithId[] = []

  const friendDocs = query(collection(db, 'accounts'), where(documentId(), 'in', ids))
  const nameQuerySnapshot = await getDocs(friendDocs)

  const idsWithPublicActionHistories: string[] = []
  nameQuerySnapshot.forEach(userDoc => {
    const id = userDoc.id
    const user = userDoc.data() as ActionToolAccountWithId
    // if user either has no hide history setting or it doesn't equal true
    if (!(user.user?.settings.privacy?.hide_history == true)) {
      idsWithPublicActionHistories.push(id)
      profiles.push({
        ...user,
        id
      })
    }
  })
  const actions: UserAction[] = []

  if (idsWithPublicActionHistories.length > 0) {
    const friendActionsQuery = query(
      collection(db, 'user_actions'),
      where('user_id', 'in', idsWithPublicActionHistories),
      orderBy('date_completed', 'desc'),
      limit(25)
    )
    const friendActionQuerySnapshot = await getDocs(friendActionsQuery)

    // TODO: paginate this
    friendActionQuerySnapshot.forEach(userAction => {
      const data = userAction.data()
      actions.push({
        ...data,
        date_completed: data.date_completed.toDate()
      } as UserAction)
    })
  }

  return { actions, profiles }
}

export const getCommunityActions = async (db: Firestore, count = 10) => {
  const q = query(collection(db, 'user_actions'), orderBy('date', 'desc'), limit(count))

  const querySnapshot = await getDocs(q)
  const caDocs: FirestoreCollections.CompletedAction[] = []

  // TODO: paginate this
  querySnapshot.forEach(completedActionDoc => {
    caDocs.push(completedActionDoc.data() as FirestoreCollections.CompletedAction)
  })

  const actions: UserAction[] = []
  for await (const caDoc of caDocs) {
    const actionDoc = await getDoc(doc(db, 'actions', caDoc.action))
    if (actionDoc.exists()) {
      const userDoc = await getDoc(doc(db, 'accounts', caDoc.user))
      if (userDoc.exists()) {
        const actionData = actionDoc.data() as FirestoreCollections.Action
        const userData = userDoc.data() as FirestoreCollections.ActionToolUser
        const userAction = {
          action_category: actionData.category,
          action_id: caDoc.action,
          action_name: actionData.title,
          date_completed: caDoc.date.toDate(),
          user_id: caDoc.user,
          user_name: userData.profile.public.name || ''
        }
        actions.push(userAction)
      }
    }
  }

  return actions
}

export const getErrorMessage = (err: unknown, message: string) => {
  if (err instanceof Error) message = err.message
  return message
}

export const getActionSentenceFromFilters = (filters: Filters) => {
  const verbTitles =
    filters.verbs &&
    (filters.verbs?.map(
      ({ title, preposition }) => title + (preposition ? ' ' + preposition : '')
    ) as string[])

  const testVerbs =
    verbTitles &&
    addSentenceStructureToStringArray(verbTitles, filters.verbsExclusive ? 'and' : 'or')

  const nounTitles = filters.nouns && (filters.nouns?.map(({ title }) => title) as string[])
  const cobenefitTitles =
    filters.cobenefits && (filters.cobenefits?.map(({ title }) => title) as string[])

  const endorsementTitles =
    filters.endorsements && (filters.endorsements?.map(({ title }) => title) as string[])

  const issueTitles = filters.issues && (filters.issues?.map(({ title }) => title) as string[])
  const skillTitles = filters.skills && (filters.skills?.map(({ title }) => title) as string[])

  return {
    verbs: testVerbs,
    nouns:
      nounTitles &&
      addSentenceStructureToStringArray(nounTitles, filters.nounsExclusive ? 'and' : 'or'),
    cobenefits:
      cobenefitTitles &&
      addSentenceStructureToStringArray(
        cobenefitTitles,
        filters.cobenefitsExclusive ? 'and' : 'or'
      ),
    skills:
      skillTitles &&
      addSentenceStructureToStringArray(skillTitles, filters.skillsExclusive ? 'and' : 'or'),
    endorsements:
      endorsementTitles &&
      addSentenceStructureToStringArray(
        endorsementTitles,
        filters.endorsementsExclusive ? 'and' : 'or'
      ),
    issues:
      issueTitles &&
      addSentenceStructureToStringArray(issueTitles, filters.issuesExclusive ? 'and' : 'or')
  }
}

export const getLocationType = (
  location?: Queries.Maybe<Queries.SanityLocation>
): LocationType | undefined => {
  if (!location?.isRegional) {
    return 'global'
  } else {
    if (location?.zipcodes && location?.zipcodes.length > 0) {
      return 'local'
    }
    if (location?.cities && location?.cities.length > 0) {
      return 'city'
    }
    if (location?.states && location?.states.length > 0) {
      return 'state'
    }
    if (location?.countries && location?.countries.length > 0) {
      return 'federal'
    }
  }
}

export const getLocationStringFromSanityLocation = (location: Queries.SanityLocation) => {
  if (!location.isRegional) {
    return 'Global'
  } else {
    let locationString = ''
    if (location.countries && location.countries.length > 0) {
      locationString += 'Countries: ' + location.countries.join(', ')
    }
    if (location.states && location.states.length > 0) {
      locationString += 'States: ' + location.states.join(', ')
    }
    if (location.cities && location.cities.length > 0) {
      locationString += 'States: ' + location.cities.join(', ')
    }
    if (location.zipcodes && location.zipcodes.length > 0) {
      locationString += 'States: ' + location.zipcodes.join(', ')
    }
    return locationString
  }
}
export const getBeHeardLocationStringFromSanityLocation = (location: Queries.SanityLocation) => {
  // if (!location.isRegional) {
  // let locationString = ''
  // if (location.zipcodes && location.zipcodes.length > 0) {
  //   return location.zipcodes
  // }
  // if (location.cities && location.cities.length > 0) {
  //   return location.cities
  // }
  if (location.states && location.states.length > 0) {
    return (location.states as string[]).sort((a, b) => a.localeCompare(b))
  }
  if (location.countries && location.countries.length > 0) {
    return (location.countries as string[]).sort((a, b) => a.localeCompare(b))
  }
  // }
}

export const removeDuplicatesBySlug = (array: Slug[]) => {
  const slugs = array.map(item => item?.slug?.current)
  const uniqueSlugs = new Set(slugs)

  return Array.from(uniqueSlugs).map(itemSlug =>
    array.find(item => item?.slug?.current === itemSlug)
  )
}

export const removeDuplicatesByRef = (array: { _ref: string }[]) => {
  const refs = array.map(({ _ref }) => _ref)
  const uniqueRefs = new Set(refs)

  return Array.from(uniqueRefs).map(ref => array.find(item => item?._ref === ref))
}

export type FilterPrimitives = {
  verbs?: string[]
  nouns?: string[]
  cobenefits?: string[]
  skills?: string[]
  endorsements?: string[]
  issues?: string[]
  mediums?: string[]
  levels?: string[]
  sorts?: string[]
  verbsExclusive?: boolean
  nounsExclusive?: boolean
  cobenefitsExclusive?: boolean
  endorsementsExclusive?: boolean
  skillsExclusive?: boolean
  issuesExclusive?: boolean

  locations?: string[]
  expandLocations?: boolean
}
export const getFiltersFromParamObject = (params: URLSearchParams): FilterPrimitives => {
  const nouns = params?.get('nouns')?.split(',') || undefined
  const verbs = params?.get('verbs')?.split(',') || undefined
  const cobenefits = params?.get('cobenefits')?.split(',') || undefined
  const skills = params?.get('skills')?.split(',') || undefined
  const endorsements = params?.get('endorsements')?.split(',') || undefined
  const issues = params?.get('issues')?.split(',') || undefined
  const mediums = params?.get('mediums')?.split(',') || undefined
  const levels = params?.get('levels')?.split(',') || undefined
  const sorts = params?.get('sorts')?.split(',') || undefined
  const verbsExclusiveParam = params.get('verbsExclusive')
  const verbsExclusive = verbsExclusiveParam !== undefined && verbsExclusiveParam === 'true'
  const nounsExclusiveParam = params.get('nounsExclusive')
  const nounsExclusive = nounsExclusiveParam !== undefined && nounsExclusiveParam === 'true'
  const cobenefitsExclusiveParam = params.get('cobenefitsExclusive')
  const cobenefitsExclusive =
    cobenefitsExclusiveParam !== undefined && cobenefitsExclusiveParam === 'true'
  const skillsExclusiveParam = params.get('skillsExclusive')
  const skillsExclusive = skillsExclusiveParam !== undefined && skillsExclusiveParam === 'true'
  const endorsementsExclusiveParam = params.get('endorsementsExclusive')
  const endorsementsExclusive =
    endorsementsExclusiveParam !== undefined && endorsementsExclusiveParam === 'true'
  const issuesExclusiveParam = params.get('issuesExclusive')
  const issuesExclusive = issuesExclusiveParam !== undefined && issuesExclusiveParam === 'true'

  // location specific

  // states
  const locations = params.get('locations')?.split(',') || undefined
  const expandLocationsParam = params.get('expandLocations')
  const expandLocations = expandLocationsParam !== undefined && expandLocationsParam === 'true'

  return {
    nouns,
    verbs,
    cobenefits,
    skills,
    endorsements,
    issues,
    mediums,
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,
    locations,
    expandLocations
  }
}

export const parameterizeFilters = (filters: FilterPrimitives) => {
  const {
    nouns,
    verbs,
    cobenefits,
    endorsements,
    issues,
    mediums,
    skills,
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    endorsementsExclusive,
    skillsExclusive,

    issuesExclusive,
    locations,
    expandLocations
  } = filters
  const params = []
  const nounParams = nouns && `nouns=${nouns}`
  const verbParams = verbs && `verbs=${verbs}`
  const endorsementParams = endorsements && `endorsements=${endorsements}`
  const issueParams = issues && `issues=${issues}`
  const mediumParams = mediums && `mediums=${mediums}`
  const cobenefitParams = cobenefits && `cobenefits=${cobenefits}`
  const skillParams = skills && `skills=${skills}`

  const levelParams = levels && `levels=${levels}`
  const sortParams = sorts && `sorts=${sorts}`
  const verbsExclusiveParam = verbsExclusive && 'verbsExclusive=true'
  const nounsExclusiveParam = nounsExclusive && 'nounsExclusive=true'
  const endorsementsExclusiveParam = endorsementsExclusive && 'endorsementsExclusive=true'
  const issuesExclusiveParam = issuesExclusive && 'issuesExclusive=true'

  const cobenefitsExclusiveParam = cobenefitsExclusive && 'cobenefitsExclusive=true'
  const skillsExclusiveParam = skillsExclusive && 'skillsExclusive=true'

  const locationsParams = locations && `locations=${locations}`
  const expandLocationsParam = locations && expandLocations && 'expandLocations=true'

  const filtersParams = [
    verbParams,
    nounParams,
    cobenefitParams,
    skillParams,
    endorsementParams,
    issueParams,
    mediumParams,
    levelParams,
    sortParams,
    verbsExclusiveParam,
    nounsExclusiveParam,
    cobenefitsExclusiveParam,
    skillsExclusiveParam,
    endorsementsExclusiveParam,
    issuesExclusiveParam,

    locationsParams,
    expandLocationsParam
  ].filter(exists => Boolean(exists))

  return filtersParams.join('&')
}

export type Filters = {
  nouns?: Queries.SanityNoun[]
  verbs?: Queries.SanityVerb[]
  cobenefits?: Queries.SanityCobenefits[]
  skills?: Queries.SanitySkill[]
  endorsements?: Queries.SanityOrganization[]
  issues?: Queries.SanityIssue[]
  mediums?: Queries.SanityMedium[]
  levels?: LocationType[]
  sorts?: string[]
  verbsExclusive?: boolean
  nounsExclusive?: boolean
  cobenefitsExclusive?: boolean
  skillsExclusive?: boolean
  endorsementsExclusive?: boolean
  issuesExclusive?: boolean

  locations?: string[]
  expandLocations?: boolean
}
export const fetchSanityFiltersFromParams = async (params: URLSearchParams): Promise<Filters> => {
  if (!isInBrowser()) {
    return {}
  }
  const {
    nouns,
    verbs,
    cobenefits,
    skills,
    endorsements,
    issues,
    mediums,
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    issuesExclusive,
    endorsementsExclusive,
    locations,
    expandLocations
  } = getFiltersFromParamObject(params)
  const parameterizedFilters = parameterizeFilters({
    nouns,
    verbs,
    cobenefits,
    endorsements,
    issues,
    mediums,
    skills
  })
  const filtersRequest = await fetch(
    `/.netlify/functions/sanity-filters-from-params?${parameterizedFilters}`
  ).catch(error => {
    throw new Error(error)
  })
  const {
    nouns: nounFilters,
    verbs: verbFilters,
    cobenefits: cobenefitsFilters,
    endorsements: endorsementsFilters,
    issues: issuesFilters,
    skills: skillsFilters,
    mediums: mediumsFilters
  } = (await filtersRequest?.json()) as {
    nouns: Queries.SanityNoun[] | undefined
    verbs: Queries.SanityVerb[] | undefined
    cobenefits: Queries.SanityCobenefits[] | undefined
    endorsements: Queries.SanityOrganization[] | undefined
    issues: Queries.SanityIssue[] | undefined
    skills: Queries.SanitySkill[] | undefined
    mediums: Queries.SanityMedium[] | undefined
  }
  return {
    nouns: nounFilters,
    verbs: verbFilters,
    cobenefits: cobenefitsFilters,
    endorsements: endorsementsFilters,
    issues: issuesFilters,
    skills: skillsFilters,
    mediums: mediumsFilters,
    levels: levels as LocationType[],
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,
    locations: locations,
    expandLocations
  }
}

export const convertFiltersToParamObject = (filters: Filters) => {
  const {
    nouns,
    verbs,
    cobenefits,
    skills,
    endorsements,
    issues,
    mediums,
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,

    locations,
    expandLocations
  } = filters

  return {
    nouns: nouns && (nouns.map(({ slug }) => getSanitySlug(slug)) as string[]),
    verbs: verbs && (verbs.map(({ slug }) => getSanitySlug(slug)) as string[]),
    cobenefits: cobenefits && (cobenefits.map(({ slug }) => getSanitySlug(slug)) as string[]),
    skills: skills && (skills.map(({ title }) => title) as string[]),
    endorsements: endorsements && (endorsements.map(({ slug }) => getSanitySlug(slug)) as string[]),
    issues: issues && (issues.map(({ slug }) => getSanitySlug(slug)) as string[]),
    mediums: mediums && (mediums.map(({ slug }) => getSanitySlug(slug)) as string[]),
    levels,
    sorts,
    verbsExclusive,
    nounsExclusive,
    cobenefitsExclusive,
    skillsExclusive,
    endorsementsExclusive,
    issuesExclusive,
    locations,
    expandLocations: locations && expandLocations
  }
}

export const fetchLocationData = async (address: string) => {
  console.log('fetchLocationData')
  const response = await fetch(`/.netlify/functions/civic-info?address=${address}`).catch(err => {
    throw new Error(err)
  })
  return (await response?.json()) as LocationResponse
}

export const fetchRelatedVerbsToNoun = async (
  nouns: Queries.SanityNoun[],
  category: Queries.SanityCategory
) => {
  const nounSlugs = nouns.map(noun => noun.slug?.current) as string[]
  const query = `nouns=${nounSlugs.join(',')}&category=${category.slug?.current}`
  const verbsRequest = await fetch(
    `/.netlify/functions/sanity-related-verbs-to-noun?${query}`
  ).catch(error => {
    throw new Error(error)
  })
  return (await verbsRequest?.json()) as Queries.SanityVerb[]
}
export const fetchOtherVerbs = async (category: Queries.SanityCategory) => {
  const query = `category=${category.slug?.current}`
  const verbsRequest = await fetch(
    `/.netlify/functions/sanity-other-verbs-than-category?${query}`
  ).catch(error => {
    throw new Error(error)
  })
  return (await verbsRequest?.json()) as Queries.SanityVerb[]
}

export const fetchActionsBySlug = async (slugs: string[]) => {
  const request = await fetch(
    `/.netlify/functions/sanity-actions-by-slug?slugs=${slugs.join(',')}`
  ).catch(error => {
    throw new Error(error)
  })
  if (request?.status !== 200) {
    throw new Error('There was an error loading this data.')
  }
  return (await request?.json()) as Queries.SanityAction[]
}
