import * as React from 'react'
import { PropsWithChildren } from 'react'
import { ActionFilters } from '../types/filter-types'
import { attempt } from '../async/utils'
import { fetchFilters } from '../async/sanity'
import { FriendSearchFilters, RecommendedFollowFilters } from '../types/firebase-types'

export type UserSearchState = {
  activeTab: string
  userSearchFilters: FriendSearchFilters
  userSearchView: 'search' | 'results'
  recommendedFollowFilters: RecommendedFollowFilters
  recommendedFollowPage: number
  userSearchPage: number
  setActiveTab: Function
  setUserSearchFilters: Function
  setUserSearchView: (view: 'search' | 'results') => void
  setUserSearchPage: (value: number) => void
  setRecommendedFollowFilters: Function
  setRecommendedFollowPage: (value: number) => void
}

export const UserSearchContext = React.createContext<UserSearchState>({
  activeTab: '1',
  userSearchFilters: {},
  userSearchView: 'search',
  recommendedFollowFilters: {},
  recommendedFollowPage: 0,
  userSearchPage: 0,
  setActiveTab: () => {},
  setUserSearchFilters: () => {},
  setUserSearchView: () => {},
  setUserSearchPage: () => {},
  setRecommendedFollowFilters: () => {},
  setRecommendedFollowPage: () => {}
})

export const UserSearchProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState('1')
  const [recommendedFollowPage, setRecommendedFollowPage] = React.useState(0)
  const [userSearchPage, setUserSearchPage] = React.useState(0)
  const [userSearchView, setUserSearchView] = React.useState<'search' | 'results'>('search')
  const [userSearchFilters, setUserSearchFilters] = React.useState<FriendSearchFilters>({})
  const [recommendedFollowFilters, setRecommendedFollowFilters] =
    React.useState<RecommendedFollowFilters>({
      cofollowers: true,
      proximity: true,
      cobenefits: true,
      skills: true,
      actions: true
    })
  const value = React.useMemo(
    () => ({
      activeTab,
      setActiveTab,
      userSearchFilters,
      userSearchPage,
      userSearchView,
      recommendedFollowFilters,
      recommendedFollowPage,
      setUserSearchFilters,
      setUserSearchView,
      setUserSearchPage,
      setRecommendedFollowPage,
      setRecommendedFollowFilters
    }),
    [
      activeTab,
      userSearchPage,
      userSearchView,
      recommendedFollowPage,
      userSearchFilters,
      recommendedFollowFilters
    ]
  )

  return <UserSearchContext.Provider value={value}>{children}</UserSearchContext.Provider>
}

export const userSearchState = () => React.useContext(UserSearchContext)
