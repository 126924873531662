import { BeehiivUser } from '../types/beehiiv-types'
import { fetchNetlify } from './netlify'

export const getUserFromEmail = async (email: string) =>
  await fetchNetlify<{ beehiivUser: BeehiivUser }>(
    `/.netlify/functions/beehiiv-account-lookup?email=${email}`
  )
export const updateSubscriberMetadata = async (
  subscription_id?: string | null,
  {
    profile_url,
    cobenefits
  }: {
    profile_url?: string
    cobenefits?: string
  } = {}
) => {
  let fetchUrl = `/.netlify/functions/beehiiv-update_subscriber_metadata?subscription_id=${subscription_id}`
  console.log('cobenefits', cobenefits)
  if (profile_url) {
    fetchUrl += `&profile_url=${profile_url}`
  }
  if (cobenefits !== undefined) {
    fetchUrl += `&cobenefits=${cobenefits == '' ? 'none' : cobenefits}`
  }
  await fetchNetlify(fetchUrl)
}
