import * as React from 'react'
import { PropsWithChildren } from 'react'
import { trackSearchQuery } from '../utilities/analytics'

import { locationState } from './location-context'

export type ActionData = {
  actions: Queries.SanityAction[]
  fetchNewActions: Function
  fetchPrevPage: Function
  fetchNextPage: Function
  prevPage?: number
  nextPage?: number
  setActions: Function
  setTotalActions: Function
  setLoading: Function
  reset: Function
  totalActions?: number
  error?: string
  loading?: boolean
}

export const ActionDataContext = React.createContext<ActionData>({
  actions: [],
  fetchNewActions: () => {},
  fetchPrevPage: () => {},
  fetchNextPage: () => {},
  setActions: () => {},
  setTotalActions: () => {},
  setLoading: () => {},
  reset: () => {}
})
let controller: AbortController | undefined

const actionsCache: {
  [key: string]: {
    actions: Queries.SanityAction[]
    count: number
    nextPage?: number
    prevPage?: number
  }
} = {}

export const ActionDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { settings: locationSettings, locationData } = locationState()
  const [query, setQuery] = React.useState<string>()
  const [loading, setLoading] = React.useState(false)
  const [prevPage, setPrevPage] = React.useState<number | undefined>()
  const [nextPage, setNextPage] = React.useState<number | undefined>()
  const [error, setError] = React.useState<string>()
  const [actions, setActions] = React.useState<Queries.SanityAction[]>([])
  const [totalActions, setTotalActions] = React.useState<number>()

  const reset = () => {
    setActions([])
    setTotalActions(0)
  }

  const fetchActions = React.useCallback(
    async (queryString = '') => {
      setLoading(true)
      if (actionsCache[queryString]) {
        setLoading(false)
        return actionsCache[queryString]
      }

      if (controller) {
        controller.abort()
        controller = undefined
      }
      controller = new AbortController()
      const signal = controller.signal
      console.log('fetching query', queryString)
      const request = await fetch(`/.netlify/functions/sanity-actions?${queryString}`, {
        signal
      }).catch(error => {
        if (error.name === 'AbortError') {
          return
        } else {
          setError(error)
        }
      })
      if (request?.status !== 200) {
        setError('There was an error loading this data.')
      }
      const results = (await request?.json()) as {
        actions: Queries.SanityAction[]
        count: number
        nextPage?: number
        prevPage?: number
      }
      actionsCache[queryString] = results
      setLoading(false)
      controller = undefined
      return results
    },
    [locationSettings]
  )

  const fetchNewActions = React.useCallback(
    async (queryString = '', force = false) => {
      if (query !== queryString || force) {
        setQuery(queryString)

        const response = await fetchActions(`page=0&${queryString}`)
        if (response) {
          const { actions, count, prevPage: newPrevPage, nextPage: newNextPage } = response

          setActions(actions)
          setTotalActions(count)
          setNextPage(newNextPage)
          setPrevPage(newPrevPage)
        }
      }
    },
    [locationData, locationSettings, query]
  )

  const fetchNextPage = React.useCallback(async () => {
    if (nextPage) {
      const {
        actions: newActions,
        prevPage,
        nextPage: newNextPage
      } = await fetchActions(`page=${nextPage}&${query}`)

      setActions([...actions, ...newActions])
      setNextPage(newNextPage)
      setPrevPage(prevPage)
    }
  }, [actions, query, locationData, locationSettings, nextPage])
  const fetchPrevPage = React.useCallback(async () => {
    const { prioritizeLocation, restrictToLocation } = locationSettings

    if (prevPage) {
      const {
        actions,
        nextPage,
        prevPage: newPrevPage
      } = await fetchActions(`page=${prevPage}&${query}`)
      setActions(actions)
      setNextPage(nextPage)
      setPrevPage(newPrevPage)
    }
  }, [query, prevPage, locationSettings, locationData])

  React.useEffect(() => {
    if (query) {
      trackSearchQuery(query)
    }
  }, [query])

  const value = React.useMemo(
    () => ({
      loading,
      actions,
      totalActions,
      error,
      prevPage,
      nextPage,
      setActions,
      fetchNewActions,
      fetchNextPage,
      fetchPrevPage,
      setLoading,
      setTotalActions,
      reset
    }),
    [
      loading,
      totalActions,
      prevPage,
      nextPage,
      fetchNewActions,
      fetchNextPage,
      fetchPrevPage,
      error,
      actions
    ]
  )

  return <ActionDataContext.Provider value={value}>{children}</ActionDataContext.Provider>
}

export const actionDataState = () => React.useContext(ActionDataContext)
