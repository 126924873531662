import { Box, Button, Tooltip, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import { authState } from '../state/auth-state'
import { VisuallyHiddenInput } from '../elements/basic-elements'
import CameraIcon from '../images/icons/camera-icon'
import profilePlaceholder from '../images/profile-placeholder.png'
import { isTouchDevice } from '../utils/browser'
import { getErrorMessage } from '../utils/data'
import { validateAvatar } from '../utils/image'

// TODO: Deleting avatar functionality

const UserAvatar = ({
  editable,
  size,
  onChange
}: {
  editable?: boolean
  size?: number
  onChange?: (string) => void
}) => {
  const { account } = authState()
  const [editButtonVisible, showEditButton] = useState(false)

  const [uploadedImage, setUploadedImage] = useState<string>()

  const avatar = uploadedImage || account?.user?.profile.public.avatar || profilePlaceholder

  const handleFileSelect = async (e: any) => {
    const fReader = new FileReader()
    const file = e.target?.files[0]
    if (!file) {
      return
    }
    fReader.readAsDataURL(e.target?.files[0])
    fReader.onloadend = async event => {
      const result = event?.target?.result as string
      if (result) {
        try {
          const { valid, reason } = validateAvatar(result)
          if (!valid) {
            alert(reason)
            return
          }

          setUploadedImage(result)

          if (onChange) {
            onChange(result)
          }
        } catch (err) {
          alert(getErrorMessage(err, 'Please select a different image.'))
        }
      }
    }
  }

  return (
    <Box
      position="relative"
      onMouseEnter={() => showEditButton(true)}
      onMouseLeave={() => showEditButton(false)}
    >
      <Avatar src={avatar} size={size} />
      {editable && (
        <Box position="absolute" left="50%" bottom={8} sx={{ transform: 'translateX(-50%)' }}>
          <Tooltip
            enterTouchDelay={0}
            placement="top"
            title={
              <Typography color="textSecondary" textAlign="center" fontSize={10}>
                {'Upload a jpg on png image <5mb'}
              </Typography>
            }
            PopperProps={{
              disablePortal: true,
              style: { width: 200 }
            }}
          >
            <Button
              component="label"
              role={undefined}
              color="primary"
              variant="contained"
              sx={{
                p: 1,
                opacity: isTouchDevice() || editButtonVisible ? 1 : 0
              }}
            >
              <CameraIcon />

              <VisuallyHiddenInput type="file" name="avatar" onChange={handleFileSelect} />
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}

export const Avatar = styled('img')<{ size?: number }>`
  ${({ size = 64 }) => `
    height: auto;
    display: inline-block;
    max-width: ${size}px;
    min-width: ${size}px;
    object-fit: cover;
    margin-bottom: 0;
    border-radius: ${size / 4}px;
    overflow: hidden;
    aspect-ratio: 1;
  `}
`

export default UserAvatar
