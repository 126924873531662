import { Box, Button, styled } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { HBox } from '../elements/basic-elements'

type SwitchButtonProps = {
  textOff: string
  textOn: string
  on?: boolean
  onToggle?: (isOn: boolean) => void
}
const SwitchButton: React.FC<SwitchButtonProps> = ({ textOff, textOn, on, onToggle }) => {
  const onButton = React.useRef<HTMLButtonElement>(null)
  const offButton = React.useRef<HTMLButtonElement>(null)
  const toggleRef = React.useRef<HTMLDivElement>(null)

  const [isOn, toggle] = React.useState(on)

  React.useEffect(() => {
    toggle(on)
  }, [on])
  React.useEffect(() => {
    if (onButton.current && offButton.current && toggleRef.current) {
      if (isOn) {
        const { width, height, left: left1 } = offButton.current.getBoundingClientRect()
        const { left: left2 } = onButton.current.getBoundingClientRect()
        toggleRef.current.style.width = width - 8 + 'px'
        toggleRef.current.style.height = height + 'px'
        toggleRef.current.style.left = left1 - left2 + 2 + 'px'
      } else {
        const { width, height } = onButton.current.getBoundingClientRect()
        toggleRef.current.style.width = width + 'px'
        toggleRef.current.style.height = height + 'px'
        toggleRef.current.style.left = '0px'
      }
    }
  }, [isOn])
  return (
    <Container>
      <Box position="relative" flex={1} display="flex">
        <Toggle ref={toggleRef}></Toggle>
        <StyledButton
          ref={onButton}
          disabled={!isOn}
          onClick={() => {
            toggle(false)
            onToggle && onToggle(false)
          }}
        >
          {textOn}
        </StyledButton>
        <StyledButton
          ref={offButton}
          disabled={isOn}
          onClick={() => {
            toggle(true)
            onToggle && onToggle(true)
          }}
        >
          {textOff}
        </StyledButton>
      </Box>
    </Container>
  )
}

const Container = styled(HBox)`
  ${({ theme }) => `
    padding: ${theme.spacing(0.5)};
    border-radius: 10px;
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.BLACK_10};
  `}
`

const StyledButton = styled(Button)`
  transition: color 0.1s linear;
  flex: 0 0 50%;
  ${({ theme }) => `
      font-size: 13px;
      padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
      border-radius: 8px;
      background: none;
      color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_30 : colors.BLACK_100};
      &:disabled {
          color: ${colors.WHITE};
      }
  `};
`

const Toggle = styled(Box)`
  ${({ theme }) => `
    position: absolute;
    left: 0;
    top: 0;
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    background: ${colors.RED_100};
    border-radius: 9px;
    transition: all 0.2s cubic-bezier(0,0,0,1);
  `}
`

export default SwitchButton
