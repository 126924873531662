import React, { Fragment } from 'react'
import { VBox } from '../../elements/basic-elements'
import { WarningOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import { T3 } from '../../elements/text-elements'
import RepresentativeButton from './representative-button'
import { locationState } from '../../context/location-context'
import { RepCardData } from './rep-card'
import { RepresentativeMap, RepresentativesObject } from '../../types/location-types'
import AddressInput from '../address-input'
import { SmallText } from '../../elements/form-elements'

const Representatives = ({
  outreach,
  onSelectRep
}: {
  outreach: Queries.SanityOutreach
  onSelectRep: (data: RepCardData) => void
}) => {
  const { locationLoading } = locationState()
  const { locationData } = locationState()

  const { targets, targetsOther } = outreach
  const representatives = targets
    ?.map((representative, i) => {
      const repOfType =
        representative &&
        locationData?.representatives &&
        locationData?.representatives[representative as keyof RepresentativeMap]
      return repOfType
    })
    .filter(repOfType => repOfType !== undefined) as RepresentativesObject[]
  const fewerRepsThanExpected =
    locationData &&
    targets &&
    representatives &&
    representatives.length < [...targets].splice(targets.indexOf('other'), 1).length

  return (
    <VBox gap={2} my={4}>
      <Typography variant="body1" color="textSecondary">
        Be Heard! Contact Your Representatives:
      </Typography>
      <Box sx={{ pointerEvents: 'all' }}>
        <AddressInput />
        {!locationData && (
          <SmallText textAlign="center" color="secondary" mt={0.5}>
            Add your location to find your local representatives
          </SmallText>
        )}
      </Box>
      {!fewerRepsThanExpected && targets && targets.length > 0 && (
        <Fragment>
          <Box>
            {locationLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Box>
                {representatives.map((representative, i) => {
                  const { office, representatives } = representative
                  return (
                    <VBox gap={1}>
                      {representatives?.map(rep => {
                        return (
                          <RepresentativeButton
                            name={`${rep.name}`}
                            title={`${rep.party}, ${office}`}
                            photo={rep.photoUrl}
                            onClick={() => {
                              onSelectRep({
                                name: rep.name,
                                party: rep.party,
                                photo: rep.photoUrl,
                                websites: rep.urls as string[],
                                title: office,
                                phones: rep.phones,
                                emails: rep.emails
                              })
                            }}
                          />
                        )
                      })}
                    </VBox>
                  )
                })}
              </Box>
            )}
          </Box>
        </Fragment>
      )}
      {fewerRepsThanExpected && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,
            borderRadius: '9px',
            background: theme => theme.palette.error.light
          }}
        >
          <WarningOutlined />
          <T3 color="textPrimary">
            We didn't find all the representatives for your area that we were hoping for. This can
            happen if your address isn't detailed enough. Consider filling out your address again so
            we can find all the people we want you to reach out to.
          </T3>
        </Box>
      )}
      {targetsOther && targetsOther.length > 0 && (
        <Box>
          <Box my={1}>
            <Typography variant="body1" color="textSecondary">
              Other folks you can contact:
            </Typography>
          </Box>
          <VBox gap={1}>
            {targetsOther.map((otherTarget, i) => {
              const { name, title, photoUrl } = otherTarget

              return (
                <RepresentativeButton
                  name={name}
                  title={title}
                  photo={photoUrl}
                  onClick={() => {
                    setActiveRep(otherTarget)
                  }}
                />
              )
            })}
          </VBox>
        </Box>
      )}
    </VBox>
  )
}

export default Representatives
