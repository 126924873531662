import 'react'
import { Typography, Button } from '@mui/material'
import React, { FC } from 'react'
import { SmallButton, VBox } from '../elements/basic-elements'
import { ErrorText } from '../elements/form-elements'

type PlaceholderProps = {
  icon?: string
  headline: string
  description?: string
  error?: string
  cta?: string
  onClick?: Function
}
const ContentPlaceholder: FC<PlaceholderProps> = ({
  icon,
  headline,
  description,
  error,
  cta,
  onClick
}) => {
  return (
    <VBox
      gap={2}
      my={8}
      justifyContent="center"
      alignItems="center"
      maxWidth={300}
      textAlign="center"
    >
      {icon && <img src={icon} />}
      <Typography fontSize={18} color="textSecondary">
        {headline}
      </Typography>
      {(error || description) && error ? (
        <ErrorText textAlign="center">{error}</ErrorText>
      ) : (
        description && (
          <Typography fontSize={15} color="textSecondary" sx={{ opacity: 0.5 }} textAlign="center">
            {description}
          </Typography>
        )
      )}
      {cta && (
        <SmallButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => onClick && onClick()}
        >
          {cta}
        </SmallButton>
      )}
    </VBox>
  )
}

export default ContentPlaceholder
