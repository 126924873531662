import {
  Box,
  Button,
  Card,
  Divider,
  Fade,
  IconButton,
  Link,
  Paper,
  Popper,
  Typography,
  styled
} from '@mui/material'
import * as React from 'react'
import { colors } from '../../constants/colors'
import { HBox, PillSmall, SquareButton, VBox } from '../../elements/basic-elements'
import { T3 } from '../../elements/text-elements'
import RepresentativePhoto from './representative-photo'

import donkey from '../../images/democrat.png'
import EmailIcon from '../../images/icons/email-icon'
import GlobalIcon from '../../images/icons/global-icon'
import PhoneIcon from '../../images/icons/phone-icon'
import elephant from '../../images/republican.png'
import { isInBrowser } from '../../utils/browser'

export type RepCardData = {
  name?: string
  photo?: string
  party?: string
  title?: string
  websites?: string[]
  script?: Queries.Maybe<string> | undefined
}
const RepCard: React.FC<RepCardData> = ({
  name,
  photo,
  party,
  title,
  websites,
  script,
  emails,
  phones
}) => {
  const partyString = party?.toLowerCase()
  const isDemocrat = partyString === 'democratic party'
  const isRepublican = partyString === 'republican party'
  const partyImage = isDemocrat ? donkey : isRepublican ? elephant : undefined

  const [emailPopperAnchorEl, setEmailPopperAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleEmailPopperClick = (event: React.MouseEvent<HTMLElement>) => {
    setEmailPopperAnchorEl(emailPopperAnchorEl ? null : event.currentTarget)
  }
  const emailOpen = Boolean(emailPopperAnchorEl)
  const emailId = emailOpen ? 'email-popper' : undefined

  const [phonePopperAnchorEl, setPhonePopperAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleCallPopperClick = (event: React.MouseEvent<HTMLElement>) => {
    setPhonePopperAnchorEl(phonePopperAnchorEl ? null : event.currentTarget)
  }
  const phoneOpen = Boolean(phonePopperAnchorEl)
  const phoneId = phoneOpen ? 'phone-popper' : undefined

  return (
    <RepCardEl>
      <VBox alignItems="center">
        <RepresentativePhoto size={8} photo={photo} />
        <Typography color="textSecondary" variant="h4" sx={{ mt: 2.5, mb: 1.5 }}>
          {name}
        </Typography>
        <HBox gap={1}>
          {party && (
            <PillSmall
              sx={{
                backgroundColor: theme =>
                  isDemocrat
                    ? colors.BLUE_DEMOCRAT_100
                    : isRepublican
                    ? colors.RED_REPUBLICAN_100
                    : theme.palette.info.main,
                display: 'flex',
                gap: 0.5,
                alignItems: 'center'
              }}
            >
              {partyImage && <img src={partyImage} width={16} height={16} />}
              <T3
                variant="body2"
                color={isRepublican || isDemocrat ? 'textPrimary' : 'textSecondary'}
              >
                {party}
              </T3>
            </PillSmall>
          )}
          {title && (
            <PillSmall
              sx={{
                backgroundColor: theme =>
                  theme.palette.mode == 'dark'
                    ? theme.palette.secondary.main
                    : colors.GLACIER_BLUE_70
              }}
            >
              <T3 variant="body2" color="textSecondary">
                {title}
              </T3>
            </PillSmall>
          )}
        </HBox>
      </VBox>
      <VBox flex={1} gap={3}>
        {websites && websites.length > 0 && (
          <WebsiteBox>
            <Typography variant="body2" color="textSecondary">
              Website:
            </Typography>
            <HBox>
              {websites.map(url => {
                return (
                  <IconButton
                    onClick={() => {
                      if (isInBrowser()) {
                        window.open(url, '_blank')
                      }
                    }}
                  >
                    <GlobalIcon />
                  </IconButton>
                )
              })}
            </HBox>
          </WebsiteBox>
        )}
        {script && (
          <Box>
            <ScriptText>Script:</ScriptText>
            <Typography variant="body2" color="textSecondary">
              {script.replace('[REP_NAME]', name)}
            </Typography>
            {emails && (
              <React.Fragment>
                <Box p={3}>
                  <Divider />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <T3 variant="body2" color="textSecondary">
                    If there is no response, you can send an email instead.
                  </T3>
                </Box>
              </React.Fragment>
            )}
          </Box>
        )}
      </VBox>
      <HBox gap={1} justifyContent="center">
        {emails && (
          <React.Fragment>
            <Button
              variant="contained"
              color={'secondary'}
              disableElevation
              aria-describedby={emailId}
              startIcon={<EmailIcon />}
              onClick={handleEmailPopperClick}
            >
              Be Heard!
            </Button>

            <Popper
              id={emailId}
              open={emailOpen}
              anchorEl={emailPopperAnchorEl}
              placement="top"
              disablePortal
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper width="100%" elevation={3}>
                    <Box px={4} sx={{ backgroundColor: 'secondary' }}>
                      {emails?.map(email => {
                        const subject = 'Test Subject'
                        const body = 'Test Body'
                        return (
                          <Link href={`mailto:${email}?subject=${subject}&body=${body}`}>
                            <Typography sx={{ p: 2 }}>{email}</Typography>
                          </Link>
                        )
                      })}
                    </Box>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </React.Fragment>
        )}
        {phones && (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              aria-describedby={phoneId}
              startIcon={<PhoneIcon />}
              onClick={handleCallPopperClick}
            >
              Be Heard!
            </Button>
            <Popper
              id={phoneId}
              open={phoneOpen}
              anchorEl={phonePopperAnchorEl}
              placement="top"
              disablePortal
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <StyledPaper width="100%" elevation={3}>
                    <Box px={4} sx={{ backgroundColor: 'secondary' }}>
                      {phones?.map(number => {
                        return (
                          <StyledLink href={`tel:${number}`}>
                            <Typography sx={{ p: 2 }}>{number}</Typography>
                          </StyledLink>
                        )
                      })}
                    </Box>
                  </StyledPaper>
                </Fade>
              )}
            </Popper>
          </React.Fragment>
        )}
      </HBox>
    </RepCardEl>
  )
}

const RepCardEl = styled(Card)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2.5)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2.5)};
  overflow: scroll;

  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.WHITE};
  `}
`

const WebsiteBox = styled(Box)`
  ${({ theme }) => `
    border-radius: ${theme.shape.borderRadius};
    padding: ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(
    2.5
  )};
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;
    align-items: center;
    background: ${
      theme.palette.mode == 'dark' ? theme.palette.secondary.main : colors.GLACIER_BLUE_70
    };
  `}
`

const ScriptText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.BLACK_50};
  text-transform: uppercase;
  letter-spacing: 0.36px;
  line-height: 1.05;
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

const StyledPaper = styled(Paper)`
  ${({ theme }) => `
      background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.GEORGIAN_BAY_10}
    `}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  ${({ theme }) => `
      color: ${theme.palette.mode == 'dark' ? colors.WHITE : colors.BLACK_100}
    `}
`

export default RepCard
