import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { ActionDataProvider } from '../context/action-data-context'
import { ActionSelectorProvider } from '../context/action-selector-context'
import { AuthProvider } from '../state/auth-state'
import { FiltersProvider } from '../state/filters-state'
import { LocationProvider } from '../context/location-context'
import { PageProvider } from '../context/page-context'
import Theme from '../context/theme-context'
import PageWithHeader from '../components/pages/page-with-header'
import { isInBrowser } from '../utils/browser'
import ResultsLayout from './results-layout'
import DefaultLayout from './default-layout'
import { CandidateDataProvider } from '../context/candidate-data-context'
import Gleap from 'gleap'
import { ColorModeProvider } from '../context/color-context'
import * as Fathom from 'fathom-client'
import { UserSearchProvider } from '../state/user-search-state'

const Layout = props => {
  const RouterWrapper = isInBrowser() ? Router : Box
  // TODO: refactor this so all data is just in one context

  if (isInBrowser()) {
    if (process.env.NODE_ENV == 'production') {
      Gleap.initialize('iwmdDvARGVUum36H1277Ryn6QFbYdEfu')
      Fathom.load('URZHUQPU')
    }
  }

  return (
    <RouterWrapper>
      <AuthProvider>
        <LocationProvider>
          <ColorModeProvider>
            <Theme>
              <PageProvider>
                <FiltersProvider>
                  {/* TODO: UserSearchProvider should really be isolated to a community page template */}
                  <UserSearchProvider>
                    {/* TODO: A number of these should really be isolated to an action data template or integrated into the Results template */}
                    <ActionDataProvider>
                      <CandidateDataProvider>
                        <ActionSelectorProvider>
                          <PageWithHeader>{<LayoutLookup {...props} />}</PageWithHeader>
                        </ActionSelectorProvider>
                      </CandidateDataProvider>
                    </ActionDataProvider>
                  </UserSearchProvider>
                </FiltersProvider>
              </PageProvider>
            </Theme>
          </ColorModeProvider>
        </LocationProvider>
      </AuthProvider>
    </RouterWrapper>
  )
}

const LayoutLookup = props => {
  if (props.pageContext.layout && props.pageContext.layout === 'results') {
    return (
      <ResultsLayout {...props} key="results">
        {props.children}
      </ResultsLayout>
    )
  } else if (props.pageContext.layout && props.pageContext.layout === 'explore') {
    return (
      <ResultsLayout {...props} key="explore">
        {props.children}
      </ResultsLayout>
    )
  }
  return <DefaultLayout>{props.children}</DefaultLayout>
}

export default Layout
