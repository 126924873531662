import React from 'react'
import { Box, Checkbox, styled, Typography } from '@mui/material'

import { addSentenceStructureToStringArray } from '../utils'
import { sitePaddingVert } from '../constants'
import { HBox, PillSmall, VBox } from '../elements/basic-elements'
import { locationState } from '../context/location-context'
import { resultsState } from '../context/results-context'
import { getActionSentenceFromFilters } from '../utils/data'
import { colors } from '../constants/colors'

import FilterBox from './filter-box'
import LocationBox from './location-box'
import { filtersState } from '../state/filters-state'

const SearchResultsDefaultHeader = ({
  forceFiltersOpen,
  onFiltersClosed
}: {
  forceFiltersOpen: boolean
  onFiltersClosed?: () => void
}) => {
  const { filters, loading: filtersLoading } = resultsState()

  const {
    verbs: verbFragment,
    nouns: nounFragment,
    cobenefits: cobenefitFragment,
    skills: skillFragment,
    endorsements: endorsementFragment,
    issues: issueFragment
  } = getActionSentenceFromFilters(filters)

  const mediumSinglularTitles =
    filters.mediums && (filters.mediums?.map(({ title }) => title) as string[])
  const mediumPluralTitles =
    filters.mediums && (filters.mediums?.map(({ plural }) => plural) as string[])

  return (
    <HeadingContainer alignItems="center">
      <Heading>
        <VBox gap={0.5} sx={{ float: 'right', ml: 1 }}>
          <FilterBox open={forceFiltersOpen} onClose={onFiltersClosed} />
          <LocationBox />
        </VBox>
        {!filtersLoading && (
          <Typography color="textSecondary" variant="h4" textAlign="left" lineHeight="1.3">
            {verbFragment ? (
              <React.Fragment>I want to {verbFragment} </React.Fragment>
            ) : (
              `Show me ${mediumPluralTitles ? addSentenceStructureToStringArray(mediumPluralTitles, 'or') : 'everything'} about `
            )}
            {nounFragment || 'anything'}
            {cobenefitFragment && (
              <React.Fragment> specifically related to {cobenefitFragment}</React.Fragment>
            )}
            {endorsementFragment && (
              <React.Fragment> endorsed by {endorsementFragment}</React.Fragment>
            )}
            {skillFragment && <React.Fragment> in need of {skillFragment} skills </React.Fragment>}
            {issueFragment && <React.Fragment> who/that support {issueFragment}</React.Fragment>}
            {filters.levels && filters.levels.length > 0 && (
              <React.Fragment>
                {' '}
                on a {addSentenceStructureToStringArray(filters.levels, 'or')} level
              </React.Fragment>
            )}
            {filters.locations && filters.locations.length > 0 && (
              <React.Fragment>
                {filters.expandLocations && ', particularly '} in{' '}
                {addSentenceStructureToStringArray(filters.locations, 'or')}
              </React.Fragment>
            )}
            {verbFragment && mediumSinglularTitles && (
              <React.Fragment>
                {' '}
                from a {addSentenceStructureToStringArray(mediumSinglularTitles, 'or')}
              </React.Fragment>
            )}
            .
          </Typography>
        )}
      </Heading>
    </HeadingContainer>
  )
}

const HeadingContainer = styled(Box)`
  ${({ theme }) => `

    padding: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)};
    border-radius: ${theme.shape.borderRadius};
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.GLACIER_BLUE_70};
    margin: 0 ${theme.spacing(3)};

    ${theme.breakpoints.up('md')} {
    margin: ${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(0)};
    }
  `}
`

const Heading = styled(Box)`
  ${({ theme }) => `
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  `}
`

const LocationFilter = styled(PillSmall)`
  ${({ theme }) => `

    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE};
  `}
`

export default SearchResultsDefaultHeader
