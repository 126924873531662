import React, { PropsWithChildren, useCallback, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from 'react-router-dom'

import { isInBrowser } from '../utils/browser'
import { getSanitySlug, isCandidateSlug } from '../utils/sanity'
import {
  convertFiltersToParamObject,
  fetchSanityFiltersFromParams,
  Filters,
  getErrorMessage,
  getFiltersFromParamObject,
  parameterizeFilters
} from '../utils/data'

import { actionDataState } from './action-data-context'
import { candidateDataState } from './candidate-data-context'
import { locationState } from './location-context'

export const ResultsContext = React.createContext<{
  init?: boolean
  loading?: boolean
  error?: string
  activeAction?: Queries.SanityAction
  activeCandidate?: Queries.SanityCandidate
  setActiveAction: Function
  setActiveCandidate: Function
  modalWasClickedInto: boolean
  showResultModal?: boolean
  setShowResultModal: Function
  setModalWasClickedInto: Function
  setLoading: Function
  filters: Filters
  changeFilters: Function
  setQuery: Function
  refreshQuery: Function
  setFiltersQuietly: React.Dispatch<React.SetStateAction<Filters>>
  listenToRouteChanges: Function
  fetchFilters: Function
  exploreTopics?: { topic: Queries.SanityExploreTopic; query: string }[]
  selectedExploreTopic?: { topic: Queries.SanityExploreTopic; query: string }
  setSelectedExploreTopic: Function
  updateExploreTopicActions: Function
}>({
  modalWasClickedInto: false,
  filters: {},
  setActiveAction: () => {},
  setActiveCandidate: () => {},
  setShowResultModal: () => {},
  setModalWasClickedInto: () => {},
  setLoading: () => {},
  changeFilters: () => {},
  setQuery: () => {},
  refreshQuery: () => {},
  setFiltersQuietly: () => {},
  listenToRouteChanges: () => {},
  fetchFilters: () => {},
  exploreTopics: [],
  selectedExploreTopic: undefined,
  setSelectedExploreTopic: () => {},
  updateExploreTopicActions: () => {}
})

export const ActionPageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { fetchNewActions, reset: resetActions, loading: actionDataLoading } = actionDataState()

  const {
    fetchNewCandidates,
    reset: resetCandidates,
    loading: candidateDataLoading
  } = candidateDataState()

  const [loading, setLoading] = React.useState(actionDataLoading || candidateDataLoading)

  const [init, setInit] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [activeAction, setActiveAction] = React.useState()
  const [activeCandidate, setActiveCandidate] = React.useState()
  const [showResultModal, setShowResultModal] = React.useState(false)
  const [modalWasClickedInto, setModalWasClickedInto] = React.useState(false)
  const [filters, setFiltersQuietly] = React.useState<Filters>({})
  const [isObserveringRouteChanges, listenToRouteChanges] = React.useState(true)
  const [query, setQuery] = React.useState<string>()

  // Explore page states
  const [exploreTopics, setExploreTopics] =
    React.useState<{ topic: Queries.SanityExploreTopic; query: string }[]>()
  const [selectedExploreTopic, setSelectedExploreTopic] = React.useState<{
    topic: Queries.SanityExploreTopic
    query: string
  }>()

  const { settings: locationSettings } = locationState()

  const windowLocation = isInBrowser() ? useLocation() : {}

  const updateQuery = useCallback(
    (queryString: string) => {
      if (window.location.pathname !== '/explore/') {
        setQuery(queryString)
      }
    },
    [window.location.pathname]
  )

  const fetchDataWithFilters = React.useCallback(
    async (queryString: string, refresh = false) => {
      if (isInBrowser()) {
        if (queryString !== query || refresh) {
          setLoading(true)
          const newFilters = await fetchFilters(queryString)
          const shouldSearchActions = !newFilters?.nouns?.every(({ slug }) =>
            isCandidateSlug(getSanitySlug(slug))
          )
          const shouldSearchCandidates = Boolean(
            newFilters?.nouns?.find(({ slug }) => isCandidateSlug(getSanitySlug(slug)))
          )

          if (shouldSearchActions) {
            await fetchNewActions(queryString, !init || refresh)
          } else {
            resetActions()
          }

          if (shouldSearchCandidates) {
            await fetchNewCandidates(queryString, !init || refresh)
          } else {
            resetCandidates()
          }

          updateQuery(queryString)

          if (!init) {
            setInit(true)
          }
          setLoading(false)
        }
      }
    },
    [init, query, locationSettings]
  )

  const fetchExploreTopics = React.useCallback(async () => {
    setLoading(true)
    const response = await fetch('/.netlify/functions/sanity-explore-topics')
    const topics = await response.json()

    const topicsWithQueries = topics.map((topic: Queries.SanityExploreTopic) => {
      return {
        topic,
        query: parameterizeFilters({
          nouns: topic.nouns!.map(noun => noun!.slug!.current as string),
          verbs: topic.verbs!.map(verb => verb!.slug!.current as string),
          endorsements: topic.endorsements?.map(endorsement => endorsement!.slug!.current as string)
        })
      }
    })

    setExploreTopics(topicsWithQueries)
    setSelectedExploreTopic(topicsWithQueries[0])
    await fetchDataWithFilters(topicsWithQueries[0].query)
  }, [])

  const getQueryStringFromParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search)
    const filterParams = getFiltersFromParamObject(params)

    return parameterizeFilters(filterParams)
  }, [window.location.search])

  React.useEffect(() => {
    const handleLocationChange = async () => {
      if (isInBrowser()) {
        if (window.location.pathname === '/explore/') {
          await fetchExploreTopics()
        } else {
          const isActionDetail = window.location.pathname.indexOf('/action/') > -1
          const isCandidateDetail = window.location.pathname.indexOf('/candidate/') > -1
          if (!isActionDetail && !isCandidateDetail) {
            await fetchDataWithFilters(getQueryStringFromParams(), true)
          }
        }
      }
    }
    if (isObserveringRouteChanges) {
      handleLocationChange()
    }
  }, [windowLocation, isObserveringRouteChanges])

  const fetchFilters = async (queryString: string) => {
    const params = new URLSearchParams(queryString)
    try {
      const filters = await fetchSanityFiltersFromParams(params)
      setFiltersQuietly(filters)
      return filters
    } catch (err) {
      setError(getErrorMessage(err, 'An error occured while loading the filters.'))
    }
  }

  const changeFilters = async (filters: Filters) => {
    const parameterizedFilters = parameterizeFilters(convertFiltersToParamObject(filters))
    navigate(`/results?${parameterizedFilters}`)

    await fetchDataWithFilters(parameterizedFilters)
  }

  const updateExploreTopicActions = async (query: string) => {
    await fetchDataWithFilters(query)
  }

  const value = React.useMemo(
    () => ({
      init,
      loading,
      error,
      activeAction,
      activeCandidate,
      filters,
      modalWasClickedInto,
      changeFilters,
      setFiltersQuietly,
      setActiveAction,
      setActiveCandidate,
      setQuery,
      showResultModal,
      setShowResultModal,
      setModalWasClickedInto,
      setLoading,
      fetchFilters,
      listenToRouteChanges,
      exploreTopics,
      selectedExploreTopic,
      setSelectedExploreTopic,
      updateExploreTopicActions
    }),
    [
      init,
      loading,
      error,
      filters,
      activeAction,
      activeCandidate,
      showResultModal,
      modalWasClickedInto,
      filters,
      exploreTopics,
      selectedExploreTopic,
      setSelectedExploreTopic
    ]
  )

  return <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>
}

export const resultsState = () => useContext(ResultsContext)
