import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { Fragment, useCallback, useLayoutEffect, useState } from 'react'

import { colors } from '../constants/colors'

import {
  ChevronLeft,
  ChevronRight,
  GridViewOutlined,
  Logout,
  Menu,
  PersonOutlineOutlined
} from '@mui/icons-material'
import { authState } from '../state/auth-state'
import logo_light from '../images/logos/wcid-logo.svg'

import { ColorScheme, pageState } from '../context/page-context'
import { HBox, VBox } from '../elements/basic-elements'
import WcidFull from '../images/icons/wcid_full'
import { isInBrowser } from '../utils/browser'
import { getFlatIconButtonStyle } from '../utils/mui'
import TooltipTitle from './tooltip-title'
import Tagline from './tagline'
import Sponsors from './sponsors'
import StaticPageLinks from './static-page-links'
import PartialScreenModal from './partial-screen-modal'
import { LogoHorz } from '../elements/logo-horz'
import CenteredModal from './centered-modal'
import Confirmation from './confirmation'
import { COLLAPSE_BUTTON_SPACING_OFFSET } from '../constants/sizes'

const colorSchemes = {
  [ColorScheme.ACTIONS]: {
    logoLight: logo_light,
    logoDark: logo_light
  },
  [ColorScheme.COMMUNITY]: {
    logoDark: logo_light,
    logoLight: logo_light,
    linkMobile: colors.BLACK_100
  }
}

const Header = () => {
  const { account, favorites, logout } = authState()
  const { navCollapsed, collapseNav, isMobile } = pageState()
  const theme = useTheme()
  const isDarkMode = theme.palette.mode == 'dark'

  const { colorScheme } = pageState()
  const scheme = colorSchemes[colorScheme]
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  // -------------------------------------------
  // confirm logout modal
  // -------------------------------------------

  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const confirmLogout = () => {
    logout()
    hideLogoutModal()
  }
  const showLogoutModal = () => {
    setLogoutModalOpen(true)
  }
  const hideLogoutModal = () => {
    setLogoutModalOpen(false)
  }

  const schemeLinkColor = 'textSecondary'
  const renderLink = (to = '', label = '', Icon: JSX.Element) => (
    <StyledLink to={to} activeClassName="active" tabIndex={-1} state={{ init: false }}>
      {navCollapsed ? (
        <StyledIconButton>{Icon}</StyledIconButton>
      ) : (
        <NavButton variant="text">
          <Typography color={schemeLinkColor} variant="body2" sx={{ fontWeight: 500 }}>
            {label}
          </Typography>
        </NavButton>
      )}
    </StyledLink>
  )

  const MenuButtons = () => (
    <Fragment>
      {renderLink('/explore', 'Explore', <GridViewOutlined />)}
      <StyledLink
        to={account ? '/profile' : '/access-account'}
        activeClassName="active"
        tabIndex={-1}
      >
        {navCollapsed ? (
          <StyledIconButton>
            <PersonOutlineOutlined />
          </StyledIconButton>
        ) : (
          <NavButton variant="text">
            <Typography color={schemeLinkColor} variant="body2" sx={{ fontWeight: 500 }}>
              {account || favorites?.length < 1 ? (
                account ? (
                  'Profile'
                ) : (
                  'Log In'
                )
              ) : (
                <Tooltip
                  enterTouchDelay={0}
                  placement="top"
                  title={
                    <TooltipTitle
                      description={[
                        `Looks like you've favorited something! Create a profile to save these actions and starting impacting the world.`
                      ]}
                    />
                  }
                >
                  <StyledBadge
                    color="primary"
                    badgeContent={favorites?.length}
                    classes={{
                      badge: 'bump-badge'
                    }}
                  >
                    {account ? 'Profile' : 'Log In'}
                  </StyledBadge>
                </Tooltip>
              )}
            </Typography>
          </NavButton>
        )}
      </StyledLink>
      {account &&
        (isMobile ? (
          <NavButton variant="text" sx={{ justifyContent: 'flex-start' }} onClick={showLogoutModal}>
            <Typography
              alignText="left"
              color={schemeLinkColor}
              variant="body2"
              sx={{ fontWeight: 500, display: 'flex', alignItems: 'center', gap: 1 }}
            >
              Logout
              <Logout sx={{ color: 'current' }} />
            </Typography>
          </NavButton>
        ) : (
          <StyledIconButton onClick={showLogoutModal}>
            <Logout sx={{ color: 'current' }} />
          </StyledIconButton>
        ))}
      {isMobile && (
        <Fragment>
          <Divider sx={{ my: 1 }} />
          <StaticPageLinks
            vertical={true}
            renderLink={(label, href, target) => (
              <StyledLink to={href} target={target} activeClassName="active" tabIndex={-1}>
                <NavButton variant="text">
                  <Typography
                    textAlign="center"
                    color={schemeLinkColor}
                    variant="body2"
                    sx={{ fontWeight: 500, display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    {label}
                  </Typography>
                </NavButton>
              </StyledLink>
            )}
          />
        </Fragment>
      )}
    </Fragment>
  )

  const logoWidth = isMobile ? 140 : 165
  return (
    <Fragment>
      <StyledHeader collapsed={navCollapsed}>
        <tr>
          <BgCell>
            <HeaderSection collapsed={navCollapsed} smallVerticalPadding={isMobile}>
              <AppBar
                sx={{
                  background: 'none',
                  boxShadow: 'none'
                }}
                position="relative"
              >
                <NavBar>
                  <Box
                    display="flex"
                    width="100%"
                    gap={isMobile ? 2 : 4}
                    justifyContent="space-between"
                    alignItems="center"
                    position="relative"
                    flexDirection={navCollapsed ? 'column' : 'row'}
                  >
                    <LogoLink
                      to="/"
                      activeClassName="active"
                      sx={{
                        width: navCollapsed ? 54 : logoWidth
                      }}
                    >
                      {navCollapsed ? (
                        <img width="100%" src={isDarkMode ? scheme.logoDark : scheme.logoLight} />
                      ) : (
                        <WcidFull />
                      )}
                    </LogoLink>
                    <NavButtons collapsed={navCollapsed}>
                      {isMobile ? (
                        <IconButton>
                          <Menu fontSize="large" onClick={() => setShowMobileMenu(true)} />
                        </IconButton>
                      ) : (
                        <MenuButtons />
                      )}
                    </NavButtons>
                  </Box>
                </NavBar>
              </AppBar>
            </HeaderSection>
          </BgCell>
          {!isMobile && (
            <CollapseButtonCell>
              <CollapseButton collapsed={navCollapsed}>
                <IconButton
                  color="secondary"
                  aria-label={navCollapsed ? 'Expand Menu' : 'Collapse Menu'}
                  sx={getFlatIconButtonStyle(
                    isDarkMode ? 'secondary.main' : 'tertiary.main',
                    colors.GEORGIAN_BAY_100
                  )}
                  onClick={() => {
                    collapseNav(!navCollapsed)
                  }}
                >
                  {navCollapsed ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
              </CollapseButton>
            </CollapseButtonCell>
          )}
        </tr>
        {!isMobile && (
          <Fragment>
            <tr height="100%">
              <BgCell height="100%">
                <HeaderSection
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent={navCollapsed ? 'flex-end' : 'flex-start'}
                  collapsed={navCollapsed}
                  sx={{ overflow: 'hidden' }}
                >
                  {!navCollapsed && (
                    <VBox flex={1} justifyContent="center">
                      <Tagline />
                    </VBox>
                  )}
                </HeaderSection>
              </BgCell>
              <td></td>
            </tr>
            <tr>
              <BgCell>
                <HeaderSection
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent={navCollapsed ? 'flex-end' : 'flex-start'}
                  collapsed={navCollapsed}
                  sx={{ overflow: 'hidden', pt: 0, pb: theme => theme.spacing(3) }}
                >
                  <Footer alignItems="flex-end" flexDirection={navCollapsed ? 'column' : 'row'}>
                    {!navCollapsed && <Sponsors />}
                    <Box flex={1} alignItems="flex-end">
                      <StaticPageLinks />
                    </Box>
                  </Footer>
                </HeaderSection>
              </BgCell>
              <td></td>
            </tr>
          </Fragment>
        )}
      </StyledHeader>

      <PartialScreenModal open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
        <VBox sx={{ minHeight: '50vh' }} justifyContent="space-between">
          <VBox onClick={() => setShowMobileMenu(false)}>
            <MenuButtons />
          </VBox>
          <Box p={1}>
            <LogoHorz size={14} objectPosition="left" invertColor={true} />
          </Box>
        </VBox>
      </PartialScreenModal>

      <CenteredModal open={logoutModalOpen} onClose={hideLogoutModal}>
        <Confirmation
          onDeny={hideLogoutModal}
          onConfirm={confirmLogout}
          title={`Confirm Logout`}
          description="Are you sure you want to logout from WCID?"
          denyText="oops, no"
          confirmText="yes"
        />
      </CenteredModal>
    </Fragment>
  )
}

const StyledHeader = styled('table')<{ collapsed: boolean }>`
  width: 100%;
  z-index: 20;
  border-collapse: collapse;
  transition:
    background 0.15s linear,
    box-shadow 0.25s linear;

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ theme, collapsed }) => `
      width: ${collapsed ? 127 : 540}px;

    `}
  }
`

const BgCell = styled('td')`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
        background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.GLACIER_BLUE_70};
    }
  `}
`

const CollapseButtonCell = styled('td')`
  position: relative;
  ${({ theme }) => `
    width: ${theme.spacing(COLLAPSE_BUTTON_SPACING_OFFSET)};
  `}
`

const HeaderSection = styled(Box)<{ collapsed: boolean; smallVerticalPadding?: boolean }>`
  ${({ theme, collapsed, smallVerticalPadding }) => `
    padding: ${theme.spacing(smallVerticalPadding ? 0.5 : 2)} ${theme.spacing(2)};

  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(4)} ${theme.spacing(collapsed ? 2 : 4)} ${theme.spacing(
      4
    )}  ${theme.spacing(collapsed ? 2 : 8)};
  `}
`

const NavButtons = styled(Box)<{ collapsed: boolean }>`
  text-align: centered;
  ${({ collapsed }) =>
    collapsed &&
    `
    display: flex;
    flex-direction: column;
  `}
`

const NavButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`

const NavBar = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => `
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_10 : colors.BLACK_100};
  `}
`

const LogoLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  position: relative;
`

const CollapseButton = styled(Box)<{ collapsed: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }

  transform: translate(-50%, -50%);
`
const StyledBadge = styled(Badge)`
  .bump-badge {
    right: -3px;
    top: -1px;
  }
`

const Footer = styled(HBox)``

export default Header
