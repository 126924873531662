import React, { Fragment, useCallback } from 'react'
import { locationState } from '../context/location-context'
import Input from './input'
import { Box, useTheme } from '@mui/material'
import { colors } from '../constants/colors'
const AddressInput = () => {
  const { setSettings, settings } = locationState()
  const { address } = settings
  const theme = useTheme()
  const [addressInput, setAddressInput] = React.useState(address)
  const changeAddress = useCallback(
    async (newAddress?: string) => {
      if (newAddress !== address) {
        await setSettings({ ...settings, address: newAddress })
      }
    },
    [address]
  )
  return (
    <Input
      hasValue={Boolean(address)}
      onCloseClick={() => {
        setAddressInput('')
        changeAddress('')
      }}
      background={theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.BLACK_10}
      label={'Your location'}
    >
      {({ TextBox }) => {
        return (
          <TextBox
            value={addressInput}
            onClick={e => {
              e.target.focus()
            }}
            onBlur={() => {
              changeAddress(addressInput)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                changeAddress(addressInput)
              }
            }}
            onChange={e => {
              const changedZip = e.target.value
              setAddressInput(changedZip)
            }}
          />
        )
      }}
    </Input>
  )
}
export default AddressInput
