import * as Fathom from 'fathom-client'
import { stringToQueryParams } from './string'
export const trackPage = () => {
  if (process.env.NODE_ENV == 'production') {
    Fathom.trackPageview()
  }
}
export const trackEvent = (name: string, opts?: Fathom.EventOptions) => {
  if (process.env.NODE_ENV == 'production') {
    Fathom.trackEvent(name, opts)
  } else {
    console.log('track event', name)
  }
}
const splitOnCommaAndSortAlphabetically = (query: string) => {
  const splitOnComma = query.split(',')
  if (splitOnComma.length > 0) {
    return query
      .split(',')
      .sort((a, b) => a.localeCompare(b))
      .join(',')
  } else {
    return splitOnComma[0]
  }
}
export const trackSearchQuery = (paramString: string) => {
  const params = stringToQueryParams(paramString)
  const verbs = params.get('verbs')
  const nouns = params.get('nouns')
  const cobenefits = params.get('cobenefits')
  const endorsements = params.get('endorsements')
  const issues = params.get('issues')
  const verbsString = verbs && `verbs=${splitOnCommaAndSortAlphabetically(verbs)}`
  const nounsString = nouns && `verbs=${splitOnCommaAndSortAlphabetically(nouns)}`
  const cobenefitsString = cobenefits && `verbs=${splitOnCommaAndSortAlphabetically(cobenefits)}`
  const endorsementsString =
    endorsements && `verbs=${splitOnCommaAndSortAlphabetically(endorsements)}`
  const issuesString = issues && `verbs=${splitOnCommaAndSortAlphabetically(issues)}`
  const paramsString = [
    verbsString,
    nounsString,
    cobenefitsString,
    endorsementsString,
    issuesString
  ]
    .filter(exists => Boolean(exists))
    .join('&')

  trackEvent(paramsString)
}
