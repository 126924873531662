import { useTheme } from '@mui/material'
import { useLayoutEffect, useState } from 'react'

export const isInBrowser = () => typeof window !== 'undefined'

export const isTouchDevice = () => {
  return (
    isInBrowser() &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
  )
}

export const isDarkMode = () =>
  isInBrowser() && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

export const getInitialColorMode = () => {
  if (!isInBrowser()) return 'light'
  const persistedColorPreference = window.localStorage.getItem('color-mode')
  const hasPersistedPreference = typeof persistedColorPreference === 'string'
  // If the user has explicitly chosen light or dark,
  // let's use it. Otherwise, this value will be null.
  if (hasPersistedPreference) {
    return persistedColorPreference
  }
  // If they haven't been explicit, let's check the media
  // query
  const mql = window.matchMedia('(prefers-color-scheme: dark)')
  const hasMediaQueryPreference = typeof mql.matches === 'boolean'
  if (hasMediaQueryPreference) {
    return mql.matches ? 'dark' : 'light'
  }
  // If they are using a browser/OS that doesn't support
  // color themes, let's default to 'light'.
  return 'light'
}

export const getDomain = () => {
  if (!isInBrowser()) return
  const url = new URL(window.location.href)
  return `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`
}

export const useMaterialBreakpoint = () => {
  const theme = useTheme()
  const [size, setSize] = useState<typeof theme.breakpoints.values.keys>()
  useLayoutEffect(() => {
    if (isInBrowser()) {
      const setDirection = () => {
        const { innerWidth } = window
        if (innerWidth > theme.breakpoints.values.xl) {
          setSize('xl')
        } else if (innerWidth > theme.breakpoints.values.lg) {
          setSize('lg')
        } else if (innerWidth > theme.breakpoints.values.md) {
          setSize('md')
        } else if (innerWidth > theme.breakpoints.values.sm) {
          setSize('sm')
        } else if (innerWidth > theme.breakpoints.values.xs) {
          setSize('xs')
        }
      }
      setDirection()

      window.addEventListener('resize', setDirection)
      return () => window.removeEventListener('resize', setDirection)
    }
  }, [])

  return size
}

export const triggerShare = async (text?: string, parent?: HTMLDivElement) => {
  if (isInBrowser()) {
    const url = text || window.location.href
    // if (navigator.share) {
    //   console.log(document.title, url, url)
    //   try {
    //     await navigator.share({
    //       title: document.title,
    //       url
    //     })
    //   } catch (err) {}
    // } else {
    const dummy = document.createElement('input')
    const attachTo = parent || document.body
    attachTo.appendChild(dummy)
    dummy.value = url
    dummy.select()
    document.execCommand('copy')
    attachTo.removeChild(dummy)
    return true
    // }
  }
}
