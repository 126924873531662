import { ActionFilters } from '../../types/filter-types'

import { attempt } from '../utils'
import { fetchNetlify } from '../netlify'

export const fetchActions = async (slugs: string[]) => {
  return await fetchNetlify<Queries.SanityAction[]>(
    `/.netlify/functions/sanity-actions-by-slug?slugs=${slugs.join(',')}`
  )
}

export const fetchFilters = async (
  existing: ActionFilters = {},
  backgroundUpdate: (newFilters: ActionFilters) => void
) => {
  // NOTE: breaking each filter into separate request to prevent netlify
  // function timeouts
  const getFilter = async (filter: string) =>
    await attempt(
      async () => {
        const data = await fetchNetlify<ActionFilters>(
          `/.netlify/functions/sanity-filters?${filter}=true`
        )
        return data
      },
      err => console.log(err || `Could not fetch ${filter} filters`)
    )

  let mutations = { ...existing }
  const updateInBackground = (filters?: ActionFilters) => {
    mutations = { ...mutations, ...filters }
    backgroundUpdate(mutations)
  }
  await Promise.all([
    updateInBackground({ nouns: (await getFilter('nouns'))?.nouns || [] }),
    updateInBackground({ verbs: (await getFilter('verbs'))?.verbs || [] }),
    updateInBackground({ cobenefits: (await getFilter('cobenefits'))?.cobenefits || [] }),
    updateInBackground({ mediums: (await getFilter('mediums'))?.mediums || [] }),
    updateInBackground({ skills: (await getFilter('skills'))?.skills || [] }),
    updateInBackground({ endorsements: (await getFilter('endorsements'))?.endorsements || [] }),
    updateInBackground({ issues: (await getFilter('issues'))?.issues || [] })
  ])
}
