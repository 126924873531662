export enum CandidateCategories {
  ARE_ENDORSED_BY = 'are_endorsed_by',
  SUPPORT = 'support'
}

const defaults = { nonTextBehavior: 'remove' }
export const blocksToText = (blocks: any[], opts = {}) => {
  const options = Object.assign({}, defaults, opts)
  return (blocks || []).map(block => {
    if (block._type !== 'block' || !block.children) {
      return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
    }

    return block.children.map((child: any) => child?.text).join('')
  })
}

export const createArrayString = (array: string[]) => {
  return `"${array?.join('","')}"`
}

export const getSanitySlug = (slug: Queries.Maybe<Queries.SanitySlug>) =>
  slug?.current && slug.current !== null && slug.current ? slug.current : undefined

export const sanityString = (string: Queries.Maybe<string>) => {
  return string !== null ? string : ''
}

export const getUniqueCobenefits = (cobenefits: Queries.SanityCobenefits[]) => {
  if (!cobenefits) return
  const cleanCobenefits = cobenefits.filter(cobenefit => cobenefit?.slug?.current)
  const uniqueSlugs = Array.from(new Set(cleanCobenefits.map(({ slug }) => slug?.current)))
  return uniqueSlugs.map(currSlug => cleanCobenefits.find(({ slug }) => currSlug == slug?.current))
}

export const candidateVerbSlugs = ['support', 'donate', 'fundraise']
export const candidateNounSlugs = ['candidates']

export const isCandidateSlug = (slug?: string) => slug && candidateNounSlugs.includes(slug)

export const removeDuplicatesBySlug = (array: { slug: { current: string } }[]) => {
  const slugs = array.map(item => item?.slug?.current)
  const uniqueSlugs = new Set(slugs)

  return Array.from(uniqueSlugs).map(itemSlug =>
    array.find(item => item?.slug?.current === itemSlug)
  )
}
