import {
  and,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  documentId,
  FieldValue,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  or,
  orderBy,
  Query,
  query,
  QueryCompositeFilterConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  startAt,
  updateDoc,
  where
} from 'firebase/firestore'
import { ActionToolAccountWithId, ActionToolUserWithId } from '../../types/firebase-types'

import { attempt } from '../utils'
import { app } from './config'
import { createDocumentIdQuery } from './utils'

export const createDocRef = (collection: string, id: string) => {
  const db = getFirestore(app)
  return doc(db, collection, id)
}

export const createDoc = async (collection: string, id: string, data: DocumentData) => {
  return await attempt(
    async () => {
      const docRef = await createDocRef(collection, id)
      await setDoc(docRef, data)
      const accountSnap = await getDoc(docRef)
      return accountSnap.data()
    },
    err => {
      throw new Error(err || 'Could not create document')
    }
  )
}

export const removeDoc = async (collection: string, id: string) => {
  const docRef = createDocRef(collection, id)
  await deleteDoc(docRef)
}

export const getDocsFromCollection = async <T>(
  collectionName: string,
  queryBy: QueryCompositeFilterConstraint,
  {
    orderBy: orderByString,
    desc = false,
    paginate = false,
    pageSize = 100,
    startAt: pageStart
  }: {
    orderBy?: string
    desc?: boolean
    paginate?: boolean
    pageSize?: number
    startAt?: QuerySnapshot<DocumentData, DocumentData>
  } = {}
) => {
  const db = getFirestore(app)

  let q: Query<DocumentData, DocumentData>
  if (orderByString) {
    const order = orderBy(orderByString, desc ? 'desc' : 'asc')
    if (paginate && pageStart) {
      q = query(collection(db, collectionName), queryBy, order, startAt(pageStart), limit(pageSize))
    } else {
      q = query(collection(db, collectionName), queryBy, order)
    }
  } else {
    q = query(collection(db, collectionName), queryBy)
  }

  const querySnapshot = await getDocs(q)
  const docs: DocumentData[] = []

  querySnapshot.forEach(doc => {
    const data = doc.data()
    docs.push({ ...data, id: doc.id })
  })
  return {
    data: docs as T[],
    first: querySnapshot.docs[0],
    last: querySnapshot.docs[querySnapshot.docs.length - 1]
  }
}

// TODO: consider moving account specific queries to account utility

export const getAccount = async (accountId: string) => {
  return await attempt(async () => {
    const accountRef = createDocRef('accounts', accountId)
    const docSnap = await getDoc(accountRef)
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: accountRef.id } as ActionToolAccountWithId
    } else {
      return false
    }
  })
}

export const getAccountIfPublic = async (userId: string, uid?: string) => {
  const db = getFirestore(app)
  const isPublicAndPremium = () =>
    and(where('user.settings.is_private', '==', false), where('premium_access', '==', true))
  const q = query(
    collection(db, 'accounts'),
    and(
      uid ? or(where(documentId(), '==', uid), isPublicAndPremium()) : isPublicAndPremium(),
      where('user_id', '==', userId)
    )
  )

  const querySnapshot = await getDocs(q)

  const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
  return docs.length > 0 && (docs[0] as ActionToolAccountWithId)
}

export const getAccountFromDoc = (doc: QueryDocumentSnapshot<DocumentData, DocumentData>) => {
  return { ...doc.data(), id: doc.id } as ActionToolAccountWithId
}

export const getAccountsWithUserId = async (user_id: string) => {
  const db = getFirestore(app)
  const q = query(
    collection(db, 'accounts'),
    and(where('user_id', '>=', user_id), where('user_id', '<=', user_id + '\uf8ff'))
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map(doc => getAccountFromDoc(doc))
}

export const getFollowedAccounts = async (friend_ids: string[]) => {
  const db = getFirestore(app)
  const q = query(
    collection(db, 'accounts'),
    and(where('user.settings.is_private', '==', false), createDocumentIdQuery(friend_ids))
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs
    .map(doc => getAccountFromDoc(doc))
    .reduce((publicAccounts, account) => {
      // if user either has no hide history setting or it doesn't equal true
      if (!(account.user?.settings.privacy?.hide_history == true)) {
        publicAccounts.push({
          ...account.user,
          id: account.id,
          user_id: account.user_id
        } as ActionToolUserWithId)
      }
      return publicAccounts
    }, [] as ActionToolUserWithId[])
}

export const getFollowerAccounts = async (uid: string) => {
  const db = getFirestore(app)
  const q = query(
    collection(db, 'accounts'),
    and(
      where('user.settings.is_private', '==', false),
      where('user.friends', 'array-contains', uid)
    )
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs
    .map(doc => getAccountFromDoc(doc))
    .reduce((publicAccounts, account) => {
      // if user either has no hide history setting or it doesn't equal true
      if (!(account.user?.settings.privacy?.hide_history == true)) {
        publicAccounts.push({
          ...account.user,
          id: account.id,
          user_id: account.user_id
        } as ActionToolUserWithId)
      }
      return publicAccounts
    }, [] as ActionToolUserWithId[])
}

export const createAccountData = async (accountId: string, data: DocumentData) => {
  const account = await createDoc('accounts', accountId, data)
  return account
}

export const observeAccountData = (
  uid: string,
  onObserved?: (user: ActionToolAccountWithId) => void
) => {
  const accountRef = createDocRef('accounts', uid)

  return onSnapshot(accountRef, doc => {
    const updatedUser = { ...doc.data(), id: accountRef.id } as ActionToolAccountWithId
    if (onObserved) {
      onObserved(updatedUser)
    }
  })
}

export const syncAccountData = async (
  accountId: string,
  updates: {
    [x: string]: FieldValue | Partial<unknown> | null
  }
) => {
  // ensure all third party account data is synced
  return await attempt(
    async () => {
      const accountRef = createDocRef('accounts', accountId)
      const accountSnap = await getDoc(accountRef)

      if (accountSnap.exists()) {
        await updateDoc(accountRef, updates)
      } else {
        throw new Error(`Account ${accountId} does not exist`)
      }
    },
    err => {
      throw new Error(err || 'Could not sync account data.')
    }
  )
}
