import {
  Box,
  CircularProgress,
  Portal,
  Typography,
  useAutocomplete,
  UseAutocompleteProps,
  useTheme
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { colors } from '../constants/colors'
import { PillLarge } from '../elements/basic-elements'
import MagGlassIcon from '../images/icons/mag-glass-icon'
import { isDarkMode, isInBrowser } from '../utils/browser'
import Input from './input'
import { set } from 'date-fns'
import { usePrevious } from '../utils/hooks'

export interface InputOption {
  slug: string
  title: string
  tags?: string[]
}

interface InputProps {
  id: string
  options: InputOption[]
  value?: InputOption
  defaultValue?: string
  placeholder?: string
  loading?: boolean
  forceOpen?: boolean
  suggestedSearchResultsPortal: React.RefObject<Element>
  revealOptionsDelay?: number
  onValueChanged: (value: any) => void
  onIsEditingChanged?: (isEditing: boolean) => void
  onCloseClick?: () => void
}

let closeOptionsTimeout: NodeJS.Timeout
let renderGroupedOptionsTimeout: NodeJS.Timeout

let ignoreBlurSelect = false

const MIN_INPUT_WIDTH = 200

const ActionInput = (props: InputProps) => {
  const { id, revealOptionsDelay, options = [] } = props
  const [isEditing, setIsEditing] = useState(props.forceOpen)
  // const [cycleInputOptions, shouldCycleInputOptions] = useState(false)
  const [value, setValue] = useState(props.value)
  const [inputValue, setInputValue] = useState(props.value?.title || '')
  const [renderGroupedOptions, shouldRenderGroupedOptions] = useState(false)
  const [revealGroupedOptions, shouldRevealGroupedOptions] = useState(false)

  const [width, setWidth] = useState(MIN_INPUT_WIDTH)
  const theme = useTheme()
  const hiddenText = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.value !== value) {
      const newValue = props.value?.title
      setValue(props.value)
      setInputValue(newValue || '')
    }
  }, [props.value])

  useEffect(() => {
    if (props.forceOpen) {
      setIsEditing(true)
    }
  }, [props.forceOpen])

  // --------------------------------
  // Resize text boxes to actual text length
  // when input value changes
  // --------------------------------

  const setWidthToSelection = () => {
    requestAnimationFrame(() => {
      const el = hiddenText?.current

      if (el) {
        const elAsHtml = el as HTMLElement

        setWidth(elAsHtml.offsetWidth + 10)
      }
    })
  }

  const onInputValueChange = (value?: InputOption) => {
    ignoreBlurSelect = true
    const valueDidNotChange = props.value && !value && inputValue === ''
    setIsEditing(false)
    if (value) {
      setWidthToSelection()
    }

    const newValue = (valueDidNotChange && props.value) || value

    setValue(newValue)
    setInputValue(newValue?.title || '')
    if (props.onValueChanged) {
      props.onValueChanged(newValue)
    }
  }

  useEffect(() => {
    // resize textboxes when fonts have loaded

    requestAnimationFrame(() => {
      setWidthToSelection()
    })

    document.fonts.onloadingdone = function () {
      requestAnimationFrame(() => {
        setWidthToSelection()
      })
    }
    document.fonts.ready.then(function () {})
  }, [value, props.placeholder])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Trigger input option functionality
  // when user begins editing
  // --------------------------------

  useEffect(() => {
    if (isEditing) {
      ignoreBlurSelect = false
      // document.body.style.overflow = 'hidden'
      setInputValue('')
      // shouldCycleInputOptions(true)
      // if (closeOptionsTimeout) {
      // clearTimeout(closeOptionsTimeout)
      // }
    } else {
      // document.body.style.overflow = 'initial'
      // closeOptionsTimeout = setTimeout(() => {
      // shouldCycleInputOptions(false)
      // }, 750)
      // setTrimOptions(true)

      // ensure we're hiding options when we're no longer editing
      // to prevent weird rendering bugs related to our using portals
      shouldRenderGroupedOptions(false)
      shouldRevealGroupedOptions(false)
    }
    if (props.onIsEditingChanged) {
      props.onIsEditingChanged(isEditing)
    }
  }, [isEditing])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  const config = {
    id,
    options:
      options &&
      options.sort((a, b) => {
        const { tags: tagsA, title: titleA } = a
        const { tags: tagsB, title: titleB } = b
        const titleAMatch = titleA && titleA.indexOf(inputValue) > -1
        const titleBMatch = titleB && titleB.indexOf(inputValue) > -1
        return titleAMatch && titleBMatch ? -titleB.localeCompare(titleA) : titleAMatch ? -1 : 1
      }),
    // autoSelect: true,
    blurOnSelect: true,
    inputValue: inputValue,

    getOptionLabel: (option: InputOption) => option.title,
    onChange: (e, changedValue) => {
      onInputValueChange(changedValue as InputOption)
    },
    filterOptions: (options: InputOption[], { inputValue }) => {
      const inputValueLc = inputValue.toLowerCase()
      const filtered = options.filter(option => {
        const { tags, title } = option
        const tagMatch = tags && tags.find(tag => tag?.toLowerCase().indexOf(inputValueLc) > -1)
        const titleMatch = inputValueLc && title?.toLowerCase().indexOf(inputValueLc) > -1
        return tagMatch || titleMatch
      })

      return inputValue === '' ? options : filtered
    }
  } as UseAutocompleteProps<InputOption, false, false, false>

  if (isEditing) {
    config['open'] = true
  }

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete(config)

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Ensure we're resetting grouped options
  // when resizing window or no longer editing
  // --------------------------------

  const closeGroupedOptions = useCallback(() => {
    if (isEditing) {
      shouldRenderGroupedOptions(false)
      shouldRevealGroupedOptions(false)
      if (props.forceOpen) {
        // reveal options again when we're forcing open the portal
        requestAnimationFrame(() => {
          shouldRenderGroupedOptions(true)
        })
      }
    }
  }, [isEditing, props.forceOpen])

  useEffect(() => {
    return () => {
      // close grouped options when we leave the page
      shouldRenderGroupedOptions(false)
      shouldRevealGroupedOptions(false)
    }
  }, [])

  useEffect(() => {
    // Make sure that window resizes clears any grouped options
    // to prevent weird rendering bugs related to our using portals
    if (isInBrowser()) {
      window.addEventListener('resize', closeGroupedOptions)
    }
    return () => {
      // just clears this timeout when component dismounts if it exists
      clearTimeout(renderGroupedOptionsTimeout)
      window.removeEventListener('resize', closeGroupedOptions)
    }
  }, [])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Trigger reveal of grouped options
  // only when options change
  // --------------------------------

  const prevOptions = usePrevious(groupedOptions)
  useEffect(() => {
    const newOptions =
      groupedOptions?.length > 0 &&
      (!prevOptions || JSON.stringify(groupedOptions) !== JSON.stringify(prevOptions))

    if (newOptions) {
      clearTimeout(renderGroupedOptionsTimeout)
      renderGroupedOptionsTimeout = setTimeout(
        () => shouldRenderGroupedOptions(true),
        revealOptionsDelay || 0
      )
    } else {
    }
  }, [revealOptionsDelay, groupedOptions])

  // Make sure grouped options fits within viewport and if not, expand horizontally
  const suggestedOuter = React.createRef<HTMLDivElement>()
  const suggestedInner = React.createRef<HTMLDivElement>()

  // --------------------------------
  // --------------------------------
  // --------------------------------

  // --------------------------------
  // Reveal grouped options after
  // renderGroupedOptions is set to true
  // --------------------------------

  useEffect(() => {
    if (renderGroupedOptions) {
      const innerIsTaller = () => {
        return (
          (suggestedInner?.current?.offsetHeight || 0) >
          (suggestedOuter?.current?.offsetHeight || 0)
        )
      }
      const fitIntoViewport = () => {
        if (suggestedInner.current) {
          const startTime = Date.now()

          while (Date.now() - startTime < 1000 && innerIsTaller()) {
            suggestedInner.current.style.width = suggestedInner?.current?.offsetWidth + 10 + 'px'
          }
          shouldRevealGroupedOptions(true)
        }
      }
      requestAnimationFrame(() => {
        // nested under RAF to make sure the right element sizes are used
        if (innerIsTaller()) {
          suggestedInner.current?.removeAttribute('style')
          requestAnimationFrame(fitIntoViewport)
        } else {
          shouldRevealGroupedOptions(true)
        }
      })
    }
  }, [renderGroupedOptions])

  // --------------------------------
  // --------------------------------
  // --------------------------------

  const inputProps = getInputProps()
  const placeholder = props.placeholder
  // value
  // ? cycleInputOptions
  //   ? value.title
  //   : undefined
  // : props.placeholder

  const isLoading = props.loading
  const darkmode = isDarkMode()

  return (
    <Input
      hasValue={Boolean(value)}
      onCloseClick={() => {
        if (props.onCloseClick) {
          props.onCloseClick()
        }
      }}
      background={theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.BLACK_10}
      inputIcon={<MagGlassIcon />}
    >
      {({ Input }) => {
        return (
          <InputContainer sx={{ paddingTop: theme => theme.spacing(1) }}>
            <Box {...getRootProps()}>
              {isLoading && (
                <Loader>
                  <CircularProgress color={darkmode ? 'info' : 'secondary'} size={20} />
                </Loader>
              )}
              <Box
                sx={{
                  display: 'flex',
                  visibility: isLoading ? 'hidden' : 'visible'
                }}
              >
                <Input
                  {...getInputProps()}
                  className={hiddenText.current?.className}
                  style={{
                    maxWidth: value ? '100%' : width,
                    // minWidth: width ?? '100%',
                    width: value ? width : MIN_INPUT_WIDTH,
                    flex: 1,
                    lineHeight: 1.3,
                    fontSize: 20,
                    textOverflow: 'ellipsis'
                  }}
                  placeholder={placeholder}
                  onFocus={e => {
                    inputProps.onFocus && inputProps.onFocus(e)
                    if (!props.forceOpen) {
                      setIsEditing(true)
                    }
                  }}
                  onBlur={e => {
                    inputProps.onBlur && inputProps.onBlur(e)
                    if (!props.forceOpen) {
                      setIsEditing(false)

                      if (!ignoreBlurSelect) {
                        if (inputValue !== '' && groupedOptions[0]) {
                          onInputValueChange(groupedOptions[0] as InputOption)
                        } else {
                          onInputValueChange()
                        }
                      }
                    }
                  }}
                  onInput={e => {
                    inputProps.onChange && inputProps.onChange(e)
                    setInputValue(e.target.value || '')

                    if (props.suggestedSearchResultsPortal.current) {
                      props.suggestedSearchResultsPortal.current.scrollTo(0, 0)
                    }
                  }}
                  onKeyDown={e => {
                    inputProps.onKeyDown && inputProps.onKeyDown(e)
                  }}
                />
              </Box>
            </Box>
            {props.suggestedSearchResultsPortal?.current && (
              <Portal container={props.suggestedSearchResultsPortal?.current}>
                {renderGroupedOptions && groupedOptions.length > 0 && (
                  <SuggestedSearchBoxOuter ref={suggestedOuter}>
                    <SuggestedSearchBoxInner ref={suggestedInner}>
                      <SuggestedSearchBox {...getListboxProps()}>
                        {groupedOptions?.map((option, index) => {
                          return (
                            <SuggestedSearchOption {...getOptionProps({ option, index })}>
                              <ActionPill animate={revealGroupedOptions}>
                                <Typography variant="h6" sx={{ color: 'current' }}>
                                  {option.title}
                                </Typography>
                              </ActionPill>
                            </SuggestedSearchOption>
                          )
                        })}
                      </SuggestedSearchBox>
                    </SuggestedSearchBoxInner>
                  </SuggestedSearchBoxOuter>
                )}
              </Portal>
            )}

            <Typography
              variant="h4"
              component="span"
              style={{
                fontSize: 20,
                visibility: 'hidden',
                position: 'absolute',
                whiteSpace: 'nowrap'
              }}
              ref={hiddenText}
            >
              {value?.title || placeholder}
            </Typography>
          </InputContainer>
        )
      }}
    </Input>
  )
}

const InputContainer = styled(Box)`
  flex: 1 1 auto;

  ${({ theme }) => `
    padding: ${theme.spacing(1)} 0;
  `}
`

const SuggestedSearchBoxOuter = styled(Box)`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  float: none;
`
const SuggestedSearchBoxInner = styled(Box)`
  pointer-events: none;
  position: relative;
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`

const SuggestedSearchBox = styled('ul')`
  pointer-events: all;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  flex-wrap: wrap;
`
const SuggestedSearchOption = styled('li')`
  cursor: pointer;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.spacing(2)};
  &:focus,
  &:hover,
  &.Mui-focusVisible {
    transition-delay: 0s;
  }
  &:focus {
    outline: none;
  }
`

export const ActionPill = styled(PillLarge)<{ animate?: boolean; active?: boolean }>`
  ${({ theme, animate }) => `
  ${
    theme.palette.mode == 'dark'
      ? `
        background: ${colors.DARK_MODE_50};
          color: ${colors.WHITE};
        :hover,
        .Mui-focusVisible & {
          background: ${colors.DARK_MODE_70};
        }
      `
      : `
        background: ${colors.WHITE};
        color: ${colors.BLACK_100};
        :hover,
        .Mui-focusVisible & {
          background: ${colors.GLACIER_BLUE_70};
          color: ${colors.WHITE};
        }
      `
  }
  ${
    animate == true
      ? `
        @keyframes pop {
          0% {
            opacity: 0;
            transform: translateY(5px);
          }
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
        animation: pop 0.4s cubic-bezier(0, 0, 0, 1);
        animation-fill-mode: backwards;
          `
      : animate == false
      ? `
        opacity: 0;
      `
      : ``
  }
  `}
`

const Loader = styled(Box)`
  position: absolute;
  height: 20px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

export default ActionInput
