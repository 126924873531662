import { Button } from '@mui/material'
import React from 'react'
import { iconsByCategory } from './result-card'

const everyWebhookToken = 'lcnjTGepT0ZOsdci4C5ASEd86Tf8cOyp'

export const EveryDonateButton = ({
  organization,
  action_id
}: {
  organization: string
  action_id?: string
}) => {
  const metadata = {
    action_id
  }

  const encodedMetaData = btoa(JSON.stringify(metadata))

  return (
    <Button
      variant="contained"
      href={`https://staging.every.org/${organization}#donate?webhook_token=${everyWebhookToken}&partner_metadata=${encodedMetaData}`}
      disableElevation
      color={'primary'}
      startIcon={iconsByCategory['donate']}
      sx={{ flex: 1 }}
      target="_blank"
    >
      Donate
    </Button>
  )
}
