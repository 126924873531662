import * as React from 'react'
import { PropsWithChildren, useContext, useState } from 'react'
import { isInBrowser } from '../utilities/browser'
import { useMediaQuery, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { trackPage } from '../utilities/analytics'
import ToastAlert from '../elements/toast-alert'
import { ToastAlertTypes } from '../types/component-types'

export enum ColorScheme {
  COMMUNITY = 'community',
  ACTIONS = 'actions'
}

export const PageContext = React.createContext<{
  pageRef?: React.RefObject<HTMLDivElement>
  pageContentRef?: React.RefObject<HTMLDivElement>
  colorScheme: ColorScheme
  navCollapsed?: boolean
  isMobile?: boolean
  setColorScheme?: React.Dispatch<React.SetStateAction<ColorScheme>>
  collapseNav: React.Dispatch<React.SetStateAction<boolean>>
  trackPageView: () => void
  setAlert: ({ message, type }: { message: string; type: ToastAlertTypes }) => void
}>({
  colorScheme: ColorScheme.ACTIONS,
  collapseNav: () => {},
  trackPageView: () => {},
  setAlert: () => {}
})

export const PageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [colorScheme, setColorScheme] = useState(ColorScheme.ACTIONS)
  const [navCollapsed, collapseNav] = useState(false)
  const pageRef = React.createRef<HTMLDivElement>()
  const pageContentRef = React.createRef<HTMLDivElement>()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [allowPageTrack, setAllowPageTrack] = useState(false)
  const [blockFirstPageTrack, setBlockFirstPageTrack] = useState(true)
  const trackPageView = React.useCallback(() => {
    if (allowPageTrack) {
      console.log('track page')
      trackPage()
    } else {
      if (blockFirstPageTrack) {
        console.log('blocking track page')
        setAllowPageTrack(true)
        setBlockFirstPageTrack(false)
      }
    }
  }, [allowPageTrack, blockFirstPageTrack])

  // ------------------------------------------------
  // Alerts
  // ------------------------------------------------

  const [alert, setAlert] = useState<{ type: ToastAlertTypes; message: string }>()

  // ------------------------------------------------

  const value = React.useMemo(
    () => ({
      pageRef,
      pageContentRef,
      colorScheme,
      isMobile,
      navCollapsed,
      collapseNav,
      setColorScheme,
      trackPageView,
      setAlert
    }),
    [pageRef, pageContentRef, navCollapsed, colorScheme, trackPageView]
  )

  return (
    <PageContext.Provider value={value}>
      {children}

      <ToastAlert
        open={Boolean(alert)}
        onClose={() => setAlert(undefined)}
        message={alert?.message}
        type={alert?.type}
      />
    </PageContext.Provider>
  )
}

export const pageState = () => useContext(PageContext)
